@import url("https://fonts.cdnfonts.com/css/akira-expanded");

html {
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

#header {
  background-color: rgb(204, 204, 204);
  width: 100%;
  height: 10vh;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

body {
  background-color: #fff;
  /*background-color: #fefcfe\\\\;
  /*background-color: rgb(14, 8, 46);
  background-image: linear-gradient(
    rgb(0, 0, 0),
    rgb(0, 0, 0),
    rgb(0, 0, 0),
    rgb(14, 8, 46)
  );*/
  background-position: center;
  background-repeat: no-repeat;
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.center_content {
  justify-content: center;
  align-content: center;
}

.center_items {
  justify-items: center;
  align-items: center;
}

.bg-image {
  background-image: url("https://cdn.discordapp.com/attachments/652388167097253920/883170173815754842/3paneltransparent.png");
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  background-size: 70%;
  object-fit: center;
  padding: 12em;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-width: 2px;
  border-style: solid; */
  margin: 1%;
  z-index: -1;
  /* border-radius: 5px 5px 5px 5px;
  border-color: rgb(255, 255, 255);*/
  /*box-shadow: 0 0 50px 1px rgb(190, 190, 190) inset;*/
  transition: 350ms;
}
.bg-image {
  background-image: url("https://cdn.discordapp.com/attachments/652388167097253920/883170173815754842/3paneltransparent.png");
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  background-size: 70%;
  object-fit: center;
  padding: 12em;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-width: 2px;
  border-style: solid; */
  margin: 1%;
  z-index: -1;
  /* border-radius: 5px 5px 5px 5px;
  border-color: rgb(255, 255, 255);*/
  /*box-shadow: 0 0 50px 1px rgb(190, 190, 190) inset;*/
  transition: 350ms;
}
::selection {
  color: #000;
  background: rgb(153, 226, 255);
}
.container7 {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 10%;
  /*border-width: 2px;
  border-top: 1px solid white;*/
}

#loginBox {
  position: absolute;
  top: 25%;
  left: 37%;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 055);
  font-family: "Bebas Neue", cursive;
}
.yellow_bar {
  position: fixed;
  left: 0%;
  top: 20%;
  width: 0.8%;
  height: 50%;
  background-color: rgb(153, 226, 255);
  color: rgb(153, 226, 255);
  z-index: 5;
  border-radius: 0px 3px 3px 0px;
  border: none;
}
.gray_bar-no {
  position: fixed;
  left: 3%;
  top: 18.3%;
  width: 0.25%;
  height: 65%;
  background-color: rgb(36, 36, 36);
  color: rgb(36, 36, 36);
  z-index: 5;
  border-radius: 50px;
  border: none;
  -webkit-animation: 1s ease 0s normal forwards 1 fadein,
    5s ease 3s normal forwards infinite floating;
  animation: 1s ease 0s normal forwards 1 fadein,
    5s ease 3s normal forwards infinite floating;
  animation-timing-function: linear;
  border-style: inset 5px;
  transition: ease-in-out 200ms;
  filter: drop-shadow(50px);
}

@keyframes floating {
  0% {
    transform: translate(0px, 0);
    speed: 2000px;
  }
  25% {
    transform: translate(5px, 0);
    speed: 2000px;
  }
  50% {
    transform: translate(10px, 0);
    speed: 10px;
  }
  100% {
    transform: translate(-0px, 0);
    speed: 10px;
  }
}
.top_bar {
  position: fixed;
  display: flex;
  left: 25%;
  top: 0%;
  width: 50%;
  height: 1.5%;
  background-color: #242424;
  color: #fff;
  z-index: 2;
  border-radius: 0px 0px 35px 35px;
  border: none;
  filter: drop-shadow(500px);
}

#top_bar_loggedin {
  z-index: 3;
  transition-duration: 100ms;
  justify-content: center;
}

#top_bar_loggedin:hover {
  height: 6%;
}

#topbar_account {
  right: 1;
  justify-self: right;
}

#passwordResetBox {
  position: absolute;
  top: 25%;
  left: 40%;
}

.lang_switch {
  position: fixed;
  font-family: "Bebas Neue", cursive;
  font-size: 120%;
  margin-top: 1.5rem;
  margin-left: 1.7rem;
  z-index: 3;
}

.lang_switch button {
  padding: 2px;
  font-size: 120%;
  font-family: "Bebas Neue", cursive;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  color: #242424;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .lang_switch button {
    font-size: 130%;
  }
}
.central_container {
  top: 5%;
  right: 50%;
  bottom: 5%;
  position: absolute;
}

.showClickyCursor {
  cursor: pointer;
}

.cls-1 {
  fill: #231f20;
  stroke: #231f20;
  stroke-miterlimit: 10;
}

.svg-logo {
  transform: scale(1.5);
  position: fixed;
  width: 5%;
  height: 5%;
  left: 48%;
  z-index: 100000;
  margin-top: 20%;
  opacity: 1;
  filter: invert(100%) brightness(1000%);
  animation: 0.4s ease 3.9s normal forwards 1 opspeed,
    0.4s ease 2.8s normal forwards 1 scaling,
    0.4s ease 4s normal forwards 1 transitioning-up,
    0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 6s normal forwards 1 positioning;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.75s ease-in-out;
}
@keyframes transitioning-up {
  from {
    left: 48%;
    position: fixed;
    --speed: 0s;
  }
  to {
    left: 10%;
    position: absolute;
    --speed: 350ms;
  }
}
@keyframes positioning {
  from {
    left: 48%;
    margin-top: 10%;
    transform: scale(1.5);
    filter: invert(100%) brightness(1000%);
  }
  to {
    left: 48%;
    opacity: 1;
    margin-top: 1.5%;
    transform: scale(0.8);
    filter: invert(0) brightness(100%);
  }
}
@keyframes opspeed {
  0% {
    opacity: 1;
    --speed: 0s;
  }
  100% {
    opacity: 0;
    --speed: 1000px;
  }
}
@keyframes scaling {
  0% {
    transform: scale(1.5);
    --speed: 0s;
  }
  100% {
    transform: scale(0.8);
    --speed: 1000px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .svg-logo {
    transform: scale(1.5);
    position: fixed;
    width: 15%;
    height: 15%;
    left: 45%;
    z-index: 100000;
    margin-top: 90%;
    opacity: 1;
    filter: invert(100%) brightness(1000%);
    animation: 0.4s ease 3.9s normal forwards 1 opspeed,
      0.4s ease 2.8s normal forwards 1 scaling,
      0.4s ease 4s normal forwards 1 transitioning-up,
      0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 6s normal forwards 1 positioning;
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.75s ease-in-out;
  }
  @keyframes transitioning-up {
    from {
      left: 45%;
      position: fixed;
      --speed: 0s;
    }
    to {
      left: 10%;
      position: absolute;
      --speed: 350ms;
    }
  }
  @keyframes positioning {
    from {
      left: 48%;
      margin-top: 50%;
      transform: scale(1.5);
      filter: invert(100%) brightness(1000%);
    }
    to {
      left: 8%;
      opacity: 1;
      margin-top: 4%;
      transform: scale(0.8);
      filter: invert(0) brightness(100%);
    }
  }
  @keyframes opspeed {
    0% {
      opacity: 1;
      --speed: 0s;
    }
    100% {
      opacity: 0;
      --speed: 1000px;
    }
  }
  @keyframes scaling {
    0% {
      transform: scale(1.5);
      --speed: 0s;
    }
    100% {
      transform: scale(0.8);
      --speed: 1000px;
    }
  }
}

.full,
.full-2,
.full-3 {
  fill: #231f20;
}
.full {
  stroke: #231f20;
  stroke-miterlimit: 10;
}
.full-2 {
  font-size: 99.62px;
}
.full-2,
.full-3 {
  font-family: GoodTimesRg-Bold, Good Times;
  font-weight: 700;
}
.full-3 {
  font-size: 96.23px;
}
.svg-logo-full {
  transform: scale(1);
  position: fixed;
  width: 15%;
  height: 15%;
  left: 50%;
  z-index: 100000;
  margin-top: 22%;
  opacity: 1;
  filter: invert(100%) brightness(1000%);
  animation: 0.4s ease 3.9s normal forwards 1 opspeed2,
    0.4s ease 4s normal forwards 1 transitioning-up2,
    0.4s ease 2.8s normal forwards 1 scaling2,
    0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 4s normal forwards 1 positioning2;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.75s ease-in-out;
}
@keyframes transitioning-up2 {
  from {
    left: 50%;
    position: fixed;
    --speed: 0s;
  }
  to {
    left: 10%;
    position: absolute;
    --speed: 350ms;
  }
}
@keyframes positioning2 {
  from {
    left: 48%;
    filter: invert(100%) brightness(1000%);
  }
  to {
    left: 10%;
    filter: invert(0) brightness(100%);
  }
}
@keyframes opspeed2 {
  0% {
    opacity: 1;
    --speed: 0s;
  }
  100% {
    opacity: 0;
    --speed: 1000px;
  }
}
@keyframes scaling2 {
  0% {
    transform: scale(1);
    --speed: 0s;
  }
  100% {
    transform: scale(0.8);
    margin-top: 20%;
    left: 48%;
    --speed: 1000px;
  }
}
