.profileHeaderBox {
  left: 5vw;
  width: 90.1vw;
  height: 32.5vh;
  position: relative;
  background-color: grey;
  border-radius: 8px;
  margin-top: 14vh;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-size: cover;
  background-position: 0px 55%;
  background-repeat: no-repeat;
  /* filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.514)); */
}
.profileMainBackground {
  max-width: 100vw;
  max-height: 100vh;
  object-fit: contain;
}

.followBtn {
  position: absolute;
  right: 1vw !important;
  bottom: 1vh;
}

.pfp {
  position: absolute;
  border-radius: 100px;
  background-color: white;
  background-size: 180px;
  background-repeat: no-repeat;
  top: 0;
  z-index: 2;
  margin-top: 40vh;
  margin-left: 20vw;
  height: 180px;
  width: 180px;
  align-items: center;
}
p {
  text-align: left;
  font-family: "DM Sans", sans-serif;
  margin-top: 4.5vh;
  position: absolute;
  color: rgb(53, 53, 53);
  margin-left: 33vw;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
}
.name {
  text-align: left;
  font-family: "DM Sans", sans-serif;
  margin-top: 1vh;
  position: absolute;
  color: #000;
  margin-left: 33vw;
  font-size: 25px;
  font-weight: 500;
  align-items: center;
}
.bio {
  text-align: center;
  font-family: "DM Sans", sans-serif;
  margin-top: 12vh;
  position: absolute;
  color: rgb(17, 17, 17);
  font-size: 15px;
  font-weight: 500;
  align-items: center;
  width: 30vw;
  height: 10vh;
  margin-left: 35vw;
}

.sectionContainer {
  width: 90vw;
  display: none;
  align-items: center;
  height: 100vh;
  top: 0;
  margin-left: 5vw;
  position: absolute;
  background-color: #fff;
  border-color: #000;
  border: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-bottom-style: solid;
  margin-top: 41vh;
  z-index: -1;
}

.followBtn {
  border-color: #000;
  border-radius: 25px;
  border: 1px;
  border-style: solid;
  right: 0;
  margin-right: 20vw;
  margin-top: 51.5vh;
  top: 0;
  background-color: #fff;
  position: absolute;
  width: 10vw;
  height: 5vh;
  z-index: 50;
  cursor: pointer;
}
.followBtn:hover {
  background-color: rgb(209, 209, 209);
}
.followBtn:active {
  background-color: rgb(143, 143, 143);
}
/* this will be later once we have a post/feed thing for other posts */
.feedPageBtn {
}
.followBtn .text {
  text-align: center;
  margin-top: 6%;
  font-family: "DM Sans", sans-serif;
}
/* portfolio area /// image import page */
.mediaPageBtn {
  position: absolute;
  align-items: center;
  display: none;
  background-color: #fff;
  width: 6vw;
  top: 0;
  left: 0;
  margin-top: 30.5vh;
  margin-left: 8vw;
  height: 4vh;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 1.15rem;
  cursor: pointer;
}
/* services page for like commissions */
.servicesPageBtn {
  display: none;
  position: relative;
  align-items: center;
  background-color: #fff;
  width: 6vw;
  top: 0;
  left: 0;
  margin-top: 30.5vh;
  margin-left: 16vw;
  height: 4vh;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 1.15rem;
  /*border-right-style: solid;
  border-left-style: solid;
  border-top-style: solid;
  border-top-style: solid; */
  border-color: #000;
  border-width: 1px;
  cursor: pointer;
}
.servicesPageBtn:hover {
  border-color: rgb(0, 187, 255);
  border-bottom-style: solid;
  border-width: 4px;
}
.mediaPageBtn:hover {
  border-color: rgb(0, 187, 255);
  border-bottom-style: solid;
  border-width: 4px;
}

.contentPage {
  width: 90vw;
  align-items: center;
  height: 45vh;
  top: 0;
  margin-left: 5vw;
  position: absolute;
  background-color: #fff;
  border-color: #fff;
  border: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-bottom-style: solid;
  margin-top: 38vh;
  z-index: -2;
}

.background {
  position: absolute;
  width: 100vw;
  height: 500vh;
  top: 0;
  left: 0;
  z-index: -5;
  background-color: black;
  margin-top: 0vh;
}

.twitter {
  background-image: url("https://toppng.com/public/uploads/thumbnail/follow-me-gold-twitter-icon-vector-11563031490hal0n9kvls.png");
  background-repeat: no-repeat;
  width: 40vh;
  height: 20vw;
  transform: scale(0.09);
  top: 0;
  margin-left: 54.5vw;
  margin-top: 32.8vh;
  z-index: 10;
  position: absolute;
  cursor: pointer;
}
