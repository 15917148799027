@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap");
@import url("https://use.typekit.net/nws1eon.css");

#homeButtonContainer {
  position: relative !important;
  margin-left: 12vw;
  margin-top: 14vh;
  z-index: 3;
}

#headerContainer {
  z-index: 3;
  position: absolute !important;
}

#loggedInButton {
  position: absolute;
  z-index: 5;
  margin-top: 2.75vh;
  height: auto;
  padding: 1.45vh 1.45vw;
  text-align: center;
  right: 15px;
  font-size: 1.5vw;
}

.front-button {
  padding: 8px 20px !important;
  margin: 5 !important;
  margin-right: 1vw !important;
  margin-left: 0px !important;
  font-size: 25px !important;
}

.btn--primary:hover {
  background-color: var(--primary--color) !important;
  border-color: var(--primary--color) !important;
}

.animated-image {
  background-image: url("#https://cdn.discordapp.com/attachments/881332662013407333/925592271309652028/rushia-norm.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-position: right bottom;
  background-size: 550px;
  height: 101%;
  filter: contrast(110%), saturate(105%), brightness(95%);
  min-height: 80%;
  width: 100%;
  max-width: 100%;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 80%,
    transparent 100%
  );
  right: 0.2%;
  z-index: -1;
  animation: 1.5s ease 0s normal forwards 1 fadein,
    1.5s ease 0s normal forwards 1 movein;
  animation-delay: 0s movein;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes movein {
  from {
    top: -300px;
    --speed: 0s;
  }
  to {
    top: 0px;
    --speed: 100px;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes movein3 {
  from {
    top: -10px;
    --speed: 0s;
  }
  to {
    top: 225px;
    --speed: 100px;
  }
}
h2 {
  background-color: rgb(153, 226, 255);
}
.description {
  color: #c5c5c5;
  min-width: 20vw;
  height: 3vh;
  width: 40vw;
  margin-top: 13.5vh;
  position: relative;
  font-family: "DM Sans", sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
  padding: 5px 0px;
  letter-spacing: 0.01vw;
  font-weight: lighter;
  z-index: 2;
  margin-left: 12vw;
  animation: 2s ease 0s normal forwards 1 fadein,
    2s ease 0s normal forwards 1 movein2;
  animation-delay: 0s movein2;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes movein2 {
  from {
    top: -10px;
    --speed: 0s;
  }
  to {
    top: 110px;
    --speed: 100px;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.movie {
  position: absolute;
  left: 0vw;
  top: 15vh;
  width: 100vw;
  height: 100vh;
  background-image: url("https://mir-s3-cdn-cf.behance.net/project_modules/fs/817a2b118655203.608d7dd0ea447.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #2e2e2e;
  z-index: -2;
  opacity: 1;
  border-radius: 0px 3px 3px 0px;
  border: none;
  filter: drop-shadow(5);
}
.movie-overlay {
  position: absolute;
  left: 0vw;
  top: 15vh;
  width: 100vw;
  height: 100vh;
  background-size: 1500px;
  background-color: #000;
  z-index: -2;
  opacity: 0.5;
  border-radius: 0px 3px 3px 0px;
  border: none;
  filter: drop-shadow(5);
}
.movie-overlay-left {
  position: absolute;
  left: 0vw;
  top: 0;
  width: 50vw;
  height: 100vh;
  background-size: 1500px;
  background-color: rgb(161, 161, 161);
  z-index: -2;
  opacity: 0.2;
  border-radius: 0px 3px 3px 0px;
  border: none;
  filter: drop-shadow(5);
}
.about-section1 {
  width: 35vw;
  top: 70vh;
  height: 45vh;
  bottom: 0;
  right: 0;
  background-color: rgba(60, 83, 119, 0.123);
  border-top-left-radius: 15px;
  position: absolute;
}
.about-header {
  display: block;
  color: rgb(255, 255, 255);
  width: 55vw;
  height: 100vh;
  position: absolute;
  opacity: 0.9;
  margin-left: 2vw;
  font-family: good-times, sans-serif;
  font-weight: 700;
  font-size: 1.2vw;
  margin-top: 3.5vh;
  overflow-y: hidden;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.about-header2 {
  display: block;
  color: rgb(255, 255, 255);
  width: 55%;
  height: 100%;
  position: absolute;
  opacity: 0.9;
  margin-left: 18vw;
  font-family: good-times, sans-serif;
  font-weight: 700;
  font-size: 1.2vw;
  z-index: 1;
  margin-top: 3.5vh;
  overflow-y: hidden;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.about-text {
  display: block;
  color: rgb(255, 255, 255);
  width: 30%;
  height: 100%;
  margin-top: 8vh;
  text-align: left;
  position: relative;
  line-height: 2vh;
  opacity: 0.5;
  margin-left: 2vw;
  font-family: "DM Sans", sans-serif;
  font-weight: 300;
  font-size: 1vw;
  z-index: 1;
  overflow-y: hidden;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.about-text2 {
  display: block;
  color: rgb(255, 255, 255);
  width: 30%;
  height: 100%;
  margin-top: -45vh;
  text-align: left;
  position: relative;
  line-height: 2vh;
  opacity: 0.5;
  margin-left: 18vw;
  font-family: "DM Sans", sans-serif;
  font-weight: 300;
  font-size: 1vw;
  z-index: 1;
  overflow-y: hidden;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.bg-shade {
  background-color: rgba(39, 39, 39, 0.527);
  background-repeat: no-repeat;
  position: fixed;
  opacity: 1;
  background-size: 1500px;
  left: 0%;
  height: 100%;
  width: 100%;
  z-index: -1;
  animation: 1.5s ease 0s normal forwards 1 fadein;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.bg {
  background-image: url("https://i.pinimg.com/originals/76/2b/e1/762be1f0ac354249823e8a3ec80a830d.png");
  background-repeat: no-repeat;
  position: fixed;
  background-size: 1500px;
  left: 0%;
  height: 100%;
  width: 100%;
  z-index: -2;
  animation: 1.5s ease 0s normal forwards 1 fadein;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.wrapper {
  position: absolute;
  left: 0;
  top: 85%;
  height: 50%;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: -3;
  opacity: 0.8;
  /* background: linear-gradient(180deg, #fff 0%, #77a1bf 50%, #fff 100%); */
}
.wrapper-box {
  display: block;
  position: absolute;
  left: 0;
  top: 200%;
  height: 110%;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 0;
  background: #fff;
  overflow: visible;
}
.about {
  color: #fff;
  margin-top: 50vh;
  width: 40%;
  height: 20%;
  position: relative;
  font-family: "Bebas Neue", cursive;
  font-size: 280%;
  margin-left: 12%;
  font-weight: 500;
  z-index: 1;
  transition: ease-in-out 350ms;
}
.about-grid {
  display: grid;
  position: relative;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100px;
  padding: 5px;
  width: 40%;
  font-weight: lighter;
  align-items: center;
  font-family: "Bebas Neue", cursive;
  font-size: 140%;
  height: 5%;
  text-align: center;
  margin-left: 12%;
}
.icon-1 {
  background-image: url();
  background-repeat: no-repeat;
  position: relative;
  background-size: 1500px;
  left: 0%;
  height: 100%;
  width: 100%;
}
.icon-2 {
  background-image: url();
  background-repeat: no-repeat;
  position: relative;
  background-size: 1500px;
  left: 0%;
  height: 100%;
  width: 100%;
}
.icon-3 {
  background-image: url();
  background-repeat: no-repeat;
  position: relative;
  background-size: 1500px;
  left: 0%;
  height: 100%;
  width: 100%;
}
.icon-4 {
  background-image: url();
  background-repeat: no-repeat;
  position: relative;
  background-size: 1500px;
  left: 0%;
  height: 100%;
  width: 100%;
}
.about-2 {
  display: inline-block;
  grid-column: 2 / 4;
  grid-row: 1;
  width: 50%;
  padding: 0.5rem;
  align-items: center;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 1);
  border-width: 2px;
  border-spacing: 15px;
  border-style: groove;
  align-items: center;
}
.about-3 {
  display: inline-block;
  grid-column: 3 / 4;
  grid-row: 1;
  width: 80%;
  padding: 0.5rem;
  align-items: center;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 1);
  border-width: 2px;
  border-spacing: 15px;
  border-style: groove;
  align-items: center;
}
.about-4 {
  display: inline-block;
  grid-column: 4 / 4;
  grid-row: 1;
  width: 80%;
  padding: 0.5rem;
  align-items: center;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 1);
  border-width: 2px;
  border-spacing: 15px;
  border-style: groove;
  align-items: center;
}
.about-description {
  display: inline-block;
  width: 50vw;
  max-width: 600px;
  padding: 0.5rem;
  grid-column: 1 / 4;
  grid-row: 1;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 1);
  border-width: 2px;
  border-spacing: 15px;
  border-style: groove;
  align-items: center;
}
.rev-block {
  color: rgb(255, 255, 255);
  top: 30vh;
  width: 15%;
  height: 10%;
  position: relative;
  padding: 2rem;
  padding-bottom: 5vh;
  margin-left: 9.2vw;
  margin-top: 0vh;
  font-family: "Bebas Neue", cursive;
  font-size: 2vw;
  z-index: 1;
  overflow-y: hidden;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.kikaru {
  display: block;
  color: rgb(255, 255, 255);
  top: 25.5vh;
  width: 40vw;
  height: 7vh;
  position: relative;
  padding: 2rem;
  padding-bottom: 5vh;
  line-height: 5vh;
  margin-left: 9.25%;
  font-family: good-times, sans-serif;
  font-weight: 700;
  font-size: 7.5rem;
  z-index: 1;
  overflow-y: hidden;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.kikaru-blue {
  position: absolute;
  width: 5%;
  height: 20%;
  background-color: rgb(19, 15, 248);
  margin-left: 10vw;
  margin-top: 30vh;
}
.kikaru-white {
  position: absolute;
  width: 0.2vw;
  height: 5%;
  border-radius: 18px;
  background-color: rgb(255, 255, 255);
  margin-left: 10vw;
  margin-top: 58vh;
}
@keyframes rev-block {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}
@keyframes appear-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animation_1 {
  background-image: url("https://images-ext-2.discordapp.net/external/Dgikk2GTd1rqqyuOaQFV8rPQWybh-W77RG0nR6utToM/%3Fw%3D1200/https/designandexclusionconf.files.wordpress.com/2017/04/glitch-animation-c-alt-2000x1200.gif?width=1169&height=701");
  background-repeat: no-repeat;
  background-blend-mode: screen;
  background-size: 65%;
  width: 100%;
  height: 10%;
  margin-left: 14%;
  scale: 0.5;
  top: 50%;
  z-index: -5;
  position: absolute;
  filter: invert();
}
.slide-in {
  color: #fff;
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: fixed;
  opacity: 1;
  top: 0;
  left: 100%;
  z-index: 200;
  animation-duration: 0.75s;
  animation-delay: 5s;
  text-align: center;
  align-items: center;
  animation: 0.75s ease 4s normal forwards 1 slide-animation;
  transition: ease 350ms;
}
@keyframes slide-animation {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
    opacity: 1;
  }
}
@keyframes layer {
  0% {
    z-index: 200;
  }
  100% {
    z-index: -10;
  }
}
.cover-in {
  color: #fff;
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  align-items: center;
  position: fixed;
  opacity: 1;
  top: 0;
  text-align: center;
  left: 0;
  z-index: 200;
  animation: 0.2s ease 4.1s normal forwards 1 outin,
    0.75s ease 4.4s normal forwards 1 layer;
  transition: 0s;
}
@keyframes outin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.text-in {
  top: 45%;
  position: fixed;
  align-items: center;
  text-align: center;
  overflow-y: hidden;
  left: 45%;
  animation: 0.2s ease 1s normal forwards 1 opspeed,
    1s ease 1s normal forwards 1 transitioning;
  letter-spacing: 0.15em;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: block;
  float: left;
  margin-left: 3.125vw;
  font-size: 30px;
  pointer-events: none;
  transition: all 0.1s;
}
@keyframes transitioning {
  from {
    left: 45%;
    --speed: 0s;
  }
  to {
    left: 10%;
    --speed: 350ms;
  }
}
.roadmap {
  font-size: 200%;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: bold;
  font-style: normal;
  font-style: normal;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: #242424;
  text-align: center;
  position: absolute;
  margin-top: 2rem;
  margin-left: 48.5%;
  z-index: -6;
}
.top-angled {
  position: absolute;
  top: -140px;
  left: -50%;
  width: 200%;
  height: 200px;
  transform: rotate(4deg);
  z-index: 0;
  background-color: #242424;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.wrapper-angled {
  position: absolute;
  top: 210%;
  left: -50%;
  width: 200%;
  opacity: 1;
  height: 300px;
  transform: rotate(4deg) rotateX(180deg);
  z-index: 0;
  background: #000;
}
.featured {
  position: relative;
  text-align: center;
  align-items: center;
  font-family: "Bebas Neue", cursive;
  font-size: 40px;
  top: 15%;
  padding: 0.5rem;
  overflow-y: hidden;
  opacity: 1;
  color: #fff;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.featured-wrapper {
  position: relative;
  padding: 5.2rem;
  height: 58%;
  overflow-y: hidden;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.isVisible {
  position: relative;
  text-align: center;
  align-items: center;
  font-family: "Bebas Neue", cursive;
  font-size: 40px;
  top: 15%;
  padding: 0.5rem;
  opacity: 0;
  color: #fff;
  animation: 1.5s ease 0s normal forwards 1 fadein;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.top-LangSelector {
  margin-top: -70px;
  width: 100%;
  position: absolute;
}

.footerspace {
  position: absolute;
  height: 20%;
  width: 100%;
  left: 0;
  bottom: -58%;
  z-index: 1;
}

.btn-show {
  margin-top: 180vh;
  margin-left: 85%;
  position: absolute;
  filter: invert(1);
  text-decoration: none;
  z-index: 5;
}
.btn-show_text {
  color: black;
  text-decoration: none;
}
.btn-down {
  margin-top: 90vh;
  position: absolute;
  margin-left: 5vw;
  height: 100%;
  top: 0;
  animation: 5s ease-in-out 0s normal forwards infinite loop1;
  animation-delay: 0s loop1;
}
.btn-down:hover {
  margin-top: 80vh;
  transition: 800ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.text-4 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  position: relative;
  margin-top: 32vh;
  height: 10vh;
  text-align: center;
  font-size: 35px;
}
.text-5 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  position: relative;
  height: 0%;
  margin-left: 14vw;
  text-align: center;
  font-size: 25px;
  width: 70vw;
}
.homebtn {
  border-radius: 2px;
  margin: 18px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  position: relative;
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: var(--primary--color);
  font-family: "Bebas Neue";
  font-size: 1.35vw;
  letter-spacing: 1.1px;
  text-decoration: none;
}
@keyframes loop1 {
  0% {
    margin-top: 90vh;
  }
  40% {
    margin-top: 82vh;
  }
  100% {
    margin-top: 90vh;
  }
}
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .movie {
    margin-left: 0vw;
    width: 117vw;
    overflow-x: hidden;
  }
  .kikaru {
    width: 100%;
    margin-left: 2vw;
    font-size: 400%;
    margin-top: -5vh;
  }
  .rev-block {
    font-size: 200%;
    width: 100%;
    margin-left: 2vw;
  }
  .description {
    width: 80%;
  }
  .kikaru-blue {
    width: 18%;
    height: 15%;
    margin-left: 4vw;
  }
  .kikaru-white {
    margin-top: 50.5vh;
  }
  #homeButtonContainer {
    margin-left: 9vw;
    margin-top: 16vh;
    z-index: 3;
  }
  .movie-overlay {
    position: absolute;
    left: 0vw;
    top: 15vh;
    width: 117vw;
    height: 100vh;
    background-size: 1500px;
    background-color: #000;
    z-index: -2;
    opacity: 0.5;
    border-radius: 0px 3px 3px 0px;
    border: none;
    filter: drop-shadow(5);
  }
  .about-section1 {
    width: 100vw;
    top: 70vh;
    height: 45vh;
    bottom: 0;
    right: -3.5vh;
    background-color: rgba(60, 83, 119, 0.123);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: absolute;
  }
  .about-header {
    font-size: 120%;
    margin-left: 10vw;
    margin-top: 5vh;
  }
  .about-header2 {
    font-size: 120%;
    margin-top: 5vh;
    width: 48%;
    margin-left: 60vw;
  }
  .about-text {
    font-size: 115%;
    text-align: center;
    margin-top: 10vh;
    margin-left: 11.5vw;
  }
  .about-text2 {
    font-size: 115%;
    text-align: center;
    margin-left: 60vw;
  }
  .movie-overlay-left {
    width: 50%;
  }
  .btn-down {
    margin-left: 42.5vw;
    z-index: 2;
  }
  .btn-show {
    margin-top: 190vh;
  }
  .text-4 {
    margin-top: -20vh;
    margin-left: 15vw;
  }
  .text-5 {
    margin-left: 8vw;
    width: 100%;
  }
  .homebtn {
    padding: 1.5vh 1.25vw;
    border-radius: 1px;
    margin: 20px;
    border: none;
    position: absolute;
    outline: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: all ease-in-out 250ms;
    background-color: #000;
    font-family: "Bebas Neue";
    font-size: 1.35vw;
    letter-spacing: 1.1px;
    margin-right: -1vw;
    transform: scale(3);
    margin-top: 1vh;
    text-decoration: none;
    top: 0;
    right: 0;
  }
  #loggedInButton {
    position: absolute;
    z-index: 5;
    margin-top: 5.75vh;
    height: auto;
    padding: 0.65vh 2.45vw;
    text-align: center;
    right: 0;
    font-size: 1.8vw;
  }
  .top-LangSelector {
    opacity: 0;
  }
}
.featured-line {
  width: 100vw;
  height: 45%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  margin-top: 145vh;
  background-color: #181818;
}
.featured-text {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 136vh;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 35px;
  text-align: center;
  align-items: center;
  width: 100vw;
  height: 10vh;
}
