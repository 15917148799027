/* Apply these classes to any input box you would like to have consistent styling with */

:root {
  --primary--color: rgb(0, 0, 0);
  --button--hover--background--color: rgb(100, 100, 100);
  --main--blue: rgb(0, 187, 255);
  --default--container--button--color: rgb(48, 48, 48);
}

a {
  padding: 18px;
}

/* for buttons */
.defaultButton {
  padding: 1.25vh 1.25vw;
  border-radius: 2px;
  margin: 18px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: var(--primary--color);
  font-family: "Bebas Neue";
  font-size: 1.35vw;
  letter-spacing: 1.1px;
  text-decoration: none;
}

.defaultButtonHollowed {
  background-color: transparent;
  border-style: solid;
  border-color: #fff;
  border-width: 2px;
  color: #fff;
}

.defaultButtonHollowed:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.defaultButtonOnlyText {
  background-color: transparent !important;
}

.defaultButtonOnlyText:hover {
  color: var(--main--blue) !important;
}

.defaultButton:hover {
  background-color: var(--button--hover--background--color);
  color: var(--main--blue);
  /* letter-spacing: 1.8px; */
}

/* from old button classes, with a few changes */
.btn {
  outline: none;
  border: none;
  z-index: 3;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.btn--primary {
  background-color: var(--primary--color);
  color: #fff;
  border: 2px solid var(--primary--color);
}

.btn--primary:hover {
  border-color: var(--button--hover--background--color);
}

/* for inputs */
.defaultInput {
  padding: 12.5px;
  margin: 10px;
  border-radius: 3px;
  outline: none;
  cursor: text;
  font-family: "Bebas Neue";
  letter-spacing: 1.2px;
  border-style: none;
  background-color: rgb(73, 73, 73);
  transition-duration: 100ms;
  color: rgb(0, 187, 255);
}

.defaultInput:hover {
  color: rgb(0, 187, 255);
}

/* .defaultInput input, select, textarea {
    color: rgb(116, 114, 112) !important;
} */

.defaultInput::placeholder {
  color: rgb(116, 114, 112);
}

.defaultInput:hover {
  background-color: var(--button--hover--background--color);
}

/* for divs */
.defaultContainer {
  font-family: "Bebas Neue";
  border-radius: 2px;
  letter-spacing: 1.2px;
  border-style: none;
  padding: 40px;
  background-color: rgb(0, 0, 0);
  position: absolute;
}

.defaultContainer button {
  background-color: var(--default--container--button--color);
}

.defaultContainer h1 {
  color: rgb(134, 134, 134) !important;
  margin: 10px;
  font-size: 30px;
  display: inline-block;
}

.defaultContainer h1:hover {
  color: rgb(255, 255, 255) !important;
  margin: 10px;
  font-size: 30px;
  display: inline-block;
}
.defaultButton2 {
  padding: 15px 25px;
  border-radius: 4px;
  margin: 18px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: var(--primary--color);
  font-family: "Bebas Neue";
  font-size: 6vw;
  letter-spacing: 1.1px;
  text-decoration: none;
}
.defaultButton2:hover {
  background-color: var(--button--hover--background--color);
  color: var(--main--blue);
  /* letter-spacing: 1.8px; */
}


@media only screen and (min-width: 300px) and (max-width: 600px) {
  .defaultButton {
    padding: 15px 25px;
    border-radius: 4px;
    margin: 18px;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: all ease-in-out 250ms;
    background-color: var(--primary--color);
    font-family: "Bebas Neue";
    font-size: 6vw;
    letter-spacing: 1.1px;
    text-decoration: none;
  }
  .defaultButton2 {
    padding: 1.25vh 1.25vw;
    border-radius: 2px;
    margin: 18px;
    border: none;
    right: 0;
    outline: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: all ease-in-out 250ms;
    background-color: var(--primary--color);
    font-family: "Bebas Neue";
    font-size: 1.35vw;
    letter-spacing: 1.1px;
    margin-right: -10vw;
    text-decoration: none;
  }
}

.greyedOutField {
  background-color: grey;
  color: rgb(83, 83, 83);
  border-color: rgba(red, green, blue, 0);
}
.greyedOutField:hover {
  cursor: default;
  background-color: grey;
  color: rgb(83, 83, 83);
  border-color: rgba(0, 0, 0, 0);
}
