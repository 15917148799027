@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap");

.text-3 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  position: relative;
  margin-top: -30%;
  height: 10%;
  margin-right: 15%;
  text-align: right;
  font-size: 25px;
}
.card-1 {
  width: 50vh;
  height: 70vh;
  background-color: rgb(43, 43, 43);
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 15vh;
  margin-left: 10vw;
  border-radius: 15px;
  filter: drop-shadow(-10px 25px 8px #0b0bbe);
  align-items: center;
}
.icon-1 {
  background-image: url("https://cdn.discordapp.com/attachments/870491737834000415/933230829243596860/icon-1.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
  background-position: left;
  width: 50vw;
  transform: scale(1);
  height: 50vh;
  top: 0;
  left: 0;
  margin-top: -5vh;
  margin-left: 1.5vw;
}
.text-1 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  position: absolute;
  margin-top: 42vh;
  margin-left: 2.5vw;
  color: white;
  height: 10%;
  width: 20vw;
  text-align: center;
  font-size: 25px;
}
.card-2 {
  width: 50vh;
  height: 70vh;
  background-color: rgb(43, 43, 43);
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 15vh;
  margin-left: 37.5vw;
  border-radius: 15px;
  align-items: center;
  filter: drop-shadow(-10px 25px 8px #0b0bbe);
}
.card-3 {
  width: 50vh;
  height: 70vh;
  background-color: rgb(43, 43, 43);
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 15vh;
  margin-left: 65vw;
  border-radius: 15px;
  filter: drop-shadow(-10px 25px 8px #0b0bbe);
  align-items: center;
}
.text-2 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  position: absolute;
  margin-top: 45vh;
  margin-left: 0.4vw;
  color: white;
  height: 10%;
  width: 24vw;
  text-align: center;
  font-size: 25px;
}
.about-cards {
  margin-top: -5vh;
}
