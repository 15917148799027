.signOutBtn {
  padding: 1.5vh 1.25vw;
  border-radius: 4px;
  margin: 20px;
  border: none;
  position: absolute;
  outline: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: #000;
  font-family: "Bebas Neue";
  font-size: 1.35vw;
  letter-spacing: 1.1px;
  margin-right: 3vw;
  margin-top: 1vh;
  text-decoration: none;
  top: 0;
  right: 0;
}

.header-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 10vh;
  background-color: rgb(255, 255, 255);
  z-index: 5;
}

.header-color-bottom {
  background-color: rgb(0, 187, 255);
  width: 100vw;
  height: 0.8vh;
  position: absolute;
  top: 0;
  margin-top: 10vh;
  left: 0;
}

.svg-logo-home {
  transform: scale(0.03);
  padding: 15px 25px;
  position: absolute;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  top: 0;
  margin-top: -48vh;
  margin-left: -48vw;
  left: 0;
}
