@import url(https://fonts.cdnfonts.com/css/akira-expanded);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap);
@import url(https://use.typekit.net/nws1eon.css);
@import url(https://use.typekit.net/nws1eon.css);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap);

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Akira Expanded", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*.loading {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  color: black;
  z-index: 100;
  filter: drop-shadow(500px);
  animation: 1s ease 0s normal forwards 1 fadein2;
  animation-delay: 3s;
}*/

/* * {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
} */


@-webkit-keyframes fadein2 {
  0% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@keyframes fadein2 {
  0% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

html {
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

#header {
  background-color: rgb(204, 204, 204);
  width: 100%;
  height: 10vh;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

body {
  background-color: #fff;
  /*background-color: #fefcfe\\\\;
  /*background-color: rgb(14, 8, 46);
  background-image: linear-gradient(
    rgb(0, 0, 0),
    rgb(0, 0, 0),
    rgb(0, 0, 0),
    rgb(14, 8, 46)
  );*/
  background-position: center;
  background-repeat: no-repeat;
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.center_content {
  justify-content: center;
  align-content: center;
}

.center_items {
  justify-items: center;
  align-items: center;
}

.bg-image {
  background-image: url("https://cdn.discordapp.com/attachments/652388167097253920/883170173815754842/3paneltransparent.png");
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  background-size: 70%;
  object-fit: center;
  padding: 12em;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-width: 2px;
  border-style: solid; */
  margin: 1%;
  z-index: -1;
  /* border-radius: 5px 5px 5px 5px;
  border-color: rgb(255, 255, 255);*/
  /*box-shadow: 0 0 50px 1px rgb(190, 190, 190) inset;*/
  transition: 350ms;
}
.bg-image {
  background-image: url("https://cdn.discordapp.com/attachments/652388167097253920/883170173815754842/3paneltransparent.png");
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  background-size: 70%;
  object-fit: center;
  padding: 12em;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-width: 2px;
  border-style: solid; */
  margin: 1%;
  z-index: -1;
  /* border-radius: 5px 5px 5px 5px;
  border-color: rgb(255, 255, 255);*/
  /*box-shadow: 0 0 50px 1px rgb(190, 190, 190) inset;*/
  transition: 350ms;
}
::selection {
  color: #000;
  background: rgb(153, 226, 255);
}
.container7 {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 10%;
  /*border-width: 2px;
  border-top: 1px solid white;*/
}

#loginBox {
  position: absolute;
  top: 25%;
  left: 37%;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 055);
  font-family: "Bebas Neue", cursive;
}
.yellow_bar {
  position: fixed;
  left: 0%;
  top: 20%;
  width: 0.8%;
  height: 50%;
  background-color: rgb(153, 226, 255);
  color: rgb(153, 226, 255);
  z-index: 5;
  border-radius: 0px 3px 3px 0px;
  border: none;
}
.gray_bar-no {
  position: fixed;
  left: 3%;
  top: 18.3%;
  width: 0.25%;
  height: 65%;
  background-color: rgb(36, 36, 36);
  color: rgb(36, 36, 36);
  z-index: 5;
  border-radius: 50px;
  border: none;
  -webkit-animation: 1s ease 0s normal forwards 1 fadein,
    5s ease 3s normal forwards infinite floating;
  animation: 1s ease 0s normal forwards 1 fadein,
    5s ease 3s normal forwards infinite floating;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  border-style: inset 5px;
  transition: ease-in-out 200ms;
  -webkit-filter: drop-shadow(50px);
          filter: drop-shadow(50px);
}

@-webkit-keyframes floating {
  0% {
    -webkit-transform: translate(0px, 0);
            transform: translate(0px, 0);
    speed: 2000px;
  }
  25% {
    -webkit-transform: translate(5px, 0);
            transform: translate(5px, 0);
    speed: 2000px;
  }
  50% {
    -webkit-transform: translate(10px, 0);
            transform: translate(10px, 0);
    speed: 10px;
  }
  100% {
    -webkit-transform: translate(-0px, 0);
            transform: translate(-0px, 0);
    speed: 10px;
  }
}

@keyframes floating {
  0% {
    -webkit-transform: translate(0px, 0);
            transform: translate(0px, 0);
    speed: 2000px;
  }
  25% {
    -webkit-transform: translate(5px, 0);
            transform: translate(5px, 0);
    speed: 2000px;
  }
  50% {
    -webkit-transform: translate(10px, 0);
            transform: translate(10px, 0);
    speed: 10px;
  }
  100% {
    -webkit-transform: translate(-0px, 0);
            transform: translate(-0px, 0);
    speed: 10px;
  }
}
.top_bar {
  position: fixed;
  display: flex;
  left: 25%;
  top: 0%;
  width: 50%;
  height: 1.5%;
  background-color: #242424;
  color: #fff;
  z-index: 2;
  border-radius: 0px 0px 35px 35px;
  border: none;
  -webkit-filter: drop-shadow(500px);
          filter: drop-shadow(500px);
}

#top_bar_loggedin {
  z-index: 3;
  transition-duration: 100ms;
  justify-content: center;
}

#top_bar_loggedin:hover {
  height: 6%;
}

#topbar_account {
  right: 1;
  justify-self: right;
}

#passwordResetBox {
  position: absolute;
  top: 25%;
  left: 40%;
}

.lang_switch {
  position: fixed;
  font-family: "Bebas Neue", cursive;
  font-size: 120%;
  margin-top: 1.5rem;
  margin-left: 1.7rem;
  z-index: 3;
}

.lang_switch button {
  padding: 2px;
  font-size: 120%;
  font-family: "Bebas Neue", cursive;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  color: #242424;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .lang_switch button {
    font-size: 130%;
  }
}
.central_container {
  top: 5%;
  right: 50%;
  bottom: 5%;
  position: absolute;
}

.showClickyCursor {
  cursor: pointer;
}

.cls-1 {
  fill: #231f20;
  stroke: #231f20;
  stroke-miterlimit: 10;
}

.svg-logo {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  position: fixed;
  width: 5%;
  height: 5%;
  left: 48%;
  z-index: 100000;
  margin-top: 20%;
  opacity: 1;
  -webkit-filter: invert(100%) brightness(1000%);
          filter: invert(100%) brightness(1000%);
  -webkit-animation: 0.4s ease 3.9s normal forwards 1 opspeed,
    0.4s ease 2.8s normal forwards 1 scaling,
    0.4s ease 4s normal forwards 1 transitioning-up,
    0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 6s normal forwards 1 positioning;
          animation: 0.4s ease 3.9s normal forwards 1 opspeed,
    0.4s ease 2.8s normal forwards 1 scaling,
    0.4s ease 4s normal forwards 1 transitioning-up,
    0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 6s normal forwards 1 positioning;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.75s ease-in-out;
}
@-webkit-keyframes transitioning-up {
  from {
    left: 48%;
    position: fixed;
    --speed: 0s;
  }
  to {
    left: 10%;
    position: absolute;
    --speed: 350ms;
  }
}
@keyframes transitioning-up {
  from {
    left: 48%;
    position: fixed;
    --speed: 0s;
  }
  to {
    left: 10%;
    position: absolute;
    --speed: 350ms;
  }
}
@-webkit-keyframes positioning {
  from {
    left: 48%;
    margin-top: 10%;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-filter: invert(100%) brightness(1000%);
            filter: invert(100%) brightness(1000%);
  }
  to {
    left: 48%;
    opacity: 1;
    margin-top: 1.5%;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-filter: invert(0) brightness(100%);
            filter: invert(0) brightness(100%);
  }
}
@keyframes positioning {
  from {
    left: 48%;
    margin-top: 10%;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-filter: invert(100%) brightness(1000%);
            filter: invert(100%) brightness(1000%);
  }
  to {
    left: 48%;
    opacity: 1;
    margin-top: 1.5%;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-filter: invert(0) brightness(100%);
            filter: invert(0) brightness(100%);
  }
}
@-webkit-keyframes opspeed {
  0% {
    opacity: 1;
    --speed: 0s;
  }
  100% {
    opacity: 0;
    --speed: 1000px;
  }
}
@keyframes opspeed {
  0% {
    opacity: 1;
    --speed: 0s;
  }
  100% {
    opacity: 0;
    --speed: 1000px;
  }
}
@-webkit-keyframes scaling {
  0% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    --speed: 0s;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    --speed: 1000px;
  }
}
@keyframes scaling {
  0% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    --speed: 0s;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    --speed: 1000px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .svg-logo {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    position: fixed;
    width: 15%;
    height: 15%;
    left: 45%;
    z-index: 100000;
    margin-top: 90%;
    opacity: 1;
    -webkit-filter: invert(100%) brightness(1000%);
            filter: invert(100%) brightness(1000%);
    -webkit-animation: 0.4s ease 3.9s normal forwards 1 opspeed,
      0.4s ease 2.8s normal forwards 1 scaling,
      0.4s ease 4s normal forwards 1 transitioning-up,
      0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 6s normal forwards 1 positioning;
            animation: 0.4s ease 3.9s normal forwards 1 opspeed,
      0.4s ease 2.8s normal forwards 1 scaling,
      0.4s ease 4s normal forwards 1 transitioning-up,
      0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 6s normal forwards 1 positioning;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.75s ease-in-out;
  }
  @-webkit-keyframes transitioning-up {
    from {
      left: 45%;
      position: fixed;
      --speed: 0s;
    }
    to {
      left: 10%;
      position: absolute;
      --speed: 350ms;
    }
  }
  @keyframes transitioning-up {
    from {
      left: 45%;
      position: fixed;
      --speed: 0s;
    }
    to {
      left: 10%;
      position: absolute;
      --speed: 350ms;
    }
  }
  @-webkit-keyframes positioning {
    from {
      left: 48%;
      margin-top: 50%;
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      -webkit-filter: invert(100%) brightness(1000%);
              filter: invert(100%) brightness(1000%);
    }
    to {
      left: 8%;
      opacity: 1;
      margin-top: 4%;
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-filter: invert(0) brightness(100%);
              filter: invert(0) brightness(100%);
    }
  }
  @keyframes positioning {
    from {
      left: 48%;
      margin-top: 50%;
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      -webkit-filter: invert(100%) brightness(1000%);
              filter: invert(100%) brightness(1000%);
    }
    to {
      left: 8%;
      opacity: 1;
      margin-top: 4%;
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-filter: invert(0) brightness(100%);
              filter: invert(0) brightness(100%);
    }
  }
  @-webkit-keyframes opspeed {
    0% {
      opacity: 1;
      --speed: 0s;
    }
    100% {
      opacity: 0;
      --speed: 1000px;
    }
  }
  @keyframes opspeed {
    0% {
      opacity: 1;
      --speed: 0s;
    }
    100% {
      opacity: 0;
      --speed: 1000px;
    }
  }
  @-webkit-keyframes scaling {
    0% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      --speed: 0s;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      --speed: 1000px;
    }
  }
  @keyframes scaling {
    0% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      --speed: 0s;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      --speed: 1000px;
    }
  }
}

.full,
.full-2,
.full-3 {
  fill: #231f20;
}
.full {
  stroke: #231f20;
  stroke-miterlimit: 10;
}
.full-2 {
  font-size: 99.62px;
}
.full-2,
.full-3 {
  font-family: GoodTimesRg-Bold, Good Times;
  font-weight: 700;
}
.full-3 {
  font-size: 96.23px;
}
.svg-logo-full {
  -webkit-transform: scale(1);
          transform: scale(1);
  position: fixed;
  width: 15%;
  height: 15%;
  left: 50%;
  z-index: 100000;
  margin-top: 22%;
  opacity: 1;
  -webkit-filter: invert(100%) brightness(1000%);
          filter: invert(100%) brightness(1000%);
  -webkit-animation: 0.4s ease 3.9s normal forwards 1 opspeed2,
    0.4s ease 4s normal forwards 1 transitioning-up2,
    0.4s ease 2.8s normal forwards 1 scaling2,
    0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 4s normal forwards 1 positioning2;
          animation: 0.4s ease 3.9s normal forwards 1 opspeed2,
    0.4s ease 4s normal forwards 1 transitioning-up2,
    0.4s ease 2.8s normal forwards 1 scaling2,
    0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 4s normal forwards 1 positioning2;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.75s ease-in-out;
}
@-webkit-keyframes transitioning-up2 {
  from {
    left: 50%;
    position: fixed;
    --speed: 0s;
  }
  to {
    left: 10%;
    position: absolute;
    --speed: 350ms;
  }
}
@keyframes transitioning-up2 {
  from {
    left: 50%;
    position: fixed;
    --speed: 0s;
  }
  to {
    left: 10%;
    position: absolute;
    --speed: 350ms;
  }
}
@-webkit-keyframes positioning2 {
  from {
    left: 48%;
    -webkit-filter: invert(100%) brightness(1000%);
            filter: invert(100%) brightness(1000%);
  }
  to {
    left: 10%;
    -webkit-filter: invert(0) brightness(100%);
            filter: invert(0) brightness(100%);
  }
}
@keyframes positioning2 {
  from {
    left: 48%;
    -webkit-filter: invert(100%) brightness(1000%);
            filter: invert(100%) brightness(1000%);
  }
  to {
    left: 10%;
    -webkit-filter: invert(0) brightness(100%);
            filter: invert(0) brightness(100%);
  }
}
@-webkit-keyframes opspeed2 {
  0% {
    opacity: 1;
    --speed: 0s;
  }
  100% {
    opacity: 0;
    --speed: 1000px;
  }
}
@keyframes opspeed2 {
  0% {
    opacity: 1;
    --speed: 0s;
  }
  100% {
    opacity: 0;
    --speed: 1000px;
  }
}
@-webkit-keyframes scaling2 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    --speed: 0s;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    margin-top: 20%;
    left: 48%;
    --speed: 1000px;
  }
}
@keyframes scaling2 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    --speed: 0s;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    margin-top: 20%;
    left: 48%;
    --speed: 1000px;
  }
}

.footer {
  background-color: #000;
  position: absolute;
  width: 100%;
  left: 0%;
  max-width: auto;
  height: 70%;
  z-index: 5;
  bottom: -250%;
  padding: 0;
  border-radius: 0;
  color: #fff;
  overflow: hidden;
}

.lang_switch {
  position: absolute;
  font-family: "Bebas Neue", cursive;
  font-size: 120%;
  margin-bottom: 10.5rem;
  margin-top: 100px;
  margin-left: 1.7rem;
  z-index: 3;
}

.footer .lang_switch {
  position: absolute;
  font-family: "Bebas Neue", cursive;
  font-size: 120%;
  margin-bottom: 10.5rem;
  margin-top: 100px;
  margin-left: 1.7rem;
  z-index: 3;
  color: #fff;
}

p {
  text-align: right;
  margin-right: 1rem;
  padding: 0rem;
  color: white;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
}

body:before {
  content: "";
  height: 100%;
  float: left;
  width: 0;
  margin-top: -32767px;
  /* thank you Erik J - negate effect of float*/
}

.footer .text {
  letter-spacing: 0.15em;
  line-height: 1em;
  text-align: center;
  font-size: 200%;
  margin-top: 6%;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  position: relative;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.footer .text_2 {
  letter-spacing: 0.15em;
  line-height: 1em;
  text-align: center;
  font-size: 100%;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  width: 100%;
  margin-top: 4%;
  position: relative;
  cursor: pointer;
}

.footer .text_3 {
  letter-spacing: 0.15em;
  line-height: 1em;
  text-align: center;
  font-size: 100%;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  height: 0%;
  margin-top: 2%;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

.footer .c_com {
  letter-spacing: 0.15em;
  line-height: 1em;
  text-align: center;
  font-size: 80%;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  margin-top: 5%;
  position: relative;
}

.footer .page_terms {
  letter-spacing: 0.15em;
  line-height: 1em;
  text-align: center;
  font-size: 60%;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  margin-top: 1%;
  position: relative;
}

.footer .p_t {
  letter-spacing: 0.15em;
  line-height: 1em;
  text-align: center;
  font-size: 80%;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  margin-top: 4%;
  position: relative;
}

.footer .svg_logo2 {
  -webkit-transform: scale(8);
          transform: scale(8);
  position: absolute;
  width: 5%;
  height: 5%;
  left: 10%;
  z-index: 100000;
  margin-top: 12%;
  opacity: 1;
  -webkit-filter: invert(0);
          filter: invert(0);
}

.footer .cls-2 {
  fill: #fff;
  stroke: #fff;
  stroke-miterlimit: 10;
}

@media only screen and (max-width: 800px) {
  .footer {
    align-items: bottom;
    bottom: -250%;
    width: 117%;
  }
  .footer .svg_logo2 {
    -webkit-transform: scale(8);
            transform: scale(8);
    position: absolute;
    width: 15%;
    height: 15%;
    left: 6%;
    z-index: 100000;
    margin-top: 12%;
    opacity: 1;
    -webkit-filter: invert(0);
            filter: invert(0);
  }
  .footer .c_com {
    margin-top: 10vh;
  }
  .footer .p_t {
    margin-top: 10vh;
  }
  .footer .page_terms {
    width: 90%;
    margin-left: 5vw;
    margin-top: 1vh;
  }
  .footer .text_2 {
    margin-top: 5vh;
  }
  .footer .text_3 {
    margin-top: 5vh;
  }
  .footer .text {
    margin-top: 5vh;
  }
}

.global-icon {
  background-image: url("https://cdn-icons-png.flaticon.com/512/117/117479.png");
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  top: 0;
  margin-left: -50vw;
  margin-top: -18.2%;
  -webkit-transform: scale(0.04);
          transform: scale(0.04);
  width: 100vw;
  height: 100vh;
  left: 0;
}
.lang_switch {
  margin-left: 5px;
  left: 5px;
}
.lang-container {
  margin-left: 5vw;
  height: 3vh;
  position: relative;
}
.lang_switch button {
  padding: 2px;
  font-size: 1.3rem;
  font-family: "Bebas Neue", cursive;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  letter-spacing: 0.05vw;
  cursor: pointer;
  color: #242424;
  text-align: center;
}
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .lang-container {
    opacity: 0;
    margin-left: 5vw;
  }
  .footer .lang-container {
    opacity: 1;
    color: white;
    margin-top: -39vh;
    z-index: 10;
    font-size: 40%;
    margin-left: 39vw;
  }
}

.signOutBtn {
  padding: 1.5vh 1.25vw;
  border-radius: 4px;
  margin: 20px;
  border: none;
  position: absolute;
  outline: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: #000;
  font-family: "Bebas Neue";
  font-size: 1.35vw;
  letter-spacing: 1.1px;
  margin-right: 3vw;
  margin-top: 1vh;
  text-decoration: none;
  top: 0;
  right: 0;
}

.header-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 10vh;
  background-color: rgb(255, 255, 255);
  z-index: 5;
}

.header-color-bottom {
  background-color: rgb(0, 187, 255);
  width: 100vw;
  height: 0.8vh;
  position: absolute;
  top: 0;
  margin-top: 10vh;
  left: 0;
}

.svg-logo-home {
  -webkit-transform: scale(0.03);
          transform: scale(0.03);
  padding: 15px 25px;
  position: absolute;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  top: 0;
  margin-top: -48vh;
  margin-left: -48vw;
  left: 0;
}

#homeButtonContainer {
  position: relative !important;
  margin-left: 12vw;
  margin-top: 14vh;
  z-index: 3;
}

#headerContainer {
  z-index: 3;
  position: absolute !important;
}

#loggedInButton {
  position: absolute;
  z-index: 5;
  margin-top: 2.75vh;
  height: auto;
  padding: 1.45vh 1.45vw;
  text-align: center;
  right: 15px;
  font-size: 1.5vw;
}

.front-button {
  padding: 8px 20px !important;
  margin: 5 !important;
  margin-right: 1vw !important;
  margin-left: 0px !important;
  font-size: 25px !important;
}

.btn--primary:hover {
  background-color: var(--primary--color) !important;
  border-color: var(--primary--color) !important;
}

.animated-image {
  background-image: url("#https://cdn.discordapp.com/attachments/881332662013407333/925592271309652028/rushia-norm.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-position: right bottom;
  background-size: 550px;
  height: 101%;
  -webkit-filter: contrast(110%), saturate(105%), brightness(95%);
          filter: contrast(110%), saturate(105%), brightness(95%);
  min-height: 80%;
  width: 100%;
  max-width: 100%;
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 80%,
    transparent 100%
  );
          mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 80%,
    transparent 100%
  );
  right: 0.2%;
  z-index: -1;
  -webkit-animation: 1.5s ease 0s normal forwards 1 fadein,
    1.5s ease 0s normal forwards 1 movein;
          animation: 1.5s ease 0s normal forwards 1 fadein,
    1.5s ease 0s normal forwards 1 movein;
  -webkit-animation-delay: 0s movein;
          animation-delay: 0s movein;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes movein {
  from {
    top: -300px;
    --speed: 0s;
  }
  to {
    top: 0px;
    --speed: 100px;
  }
}
@keyframes movein {
  from {
    top: -300px;
    --speed: 0s;
  }
  to {
    top: 0px;
    --speed: 100px;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes movein3 {
  from {
    top: -10px;
    --speed: 0s;
  }
  to {
    top: 225px;
    --speed: 100px;
  }
}
@keyframes movein3 {
  from {
    top: -10px;
    --speed: 0s;
  }
  to {
    top: 225px;
    --speed: 100px;
  }
}
h2 {
  background-color: rgb(153, 226, 255);
}
.description {
  color: #c5c5c5;
  min-width: 20vw;
  height: 3vh;
  width: 40vw;
  margin-top: 13.5vh;
  position: relative;
  font-family: "DM Sans", sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
  padding: 5px 0px;
  letter-spacing: 0.01vw;
  font-weight: lighter;
  z-index: 2;
  margin-left: 12vw;
  -webkit-animation: 2s ease 0s normal forwards 1 fadein,
    2s ease 0s normal forwards 1 movein2;
          animation: 2s ease 0s normal forwards 1 fadein,
    2s ease 0s normal forwards 1 movein2;
  -webkit-animation-delay: 0s movein2;
          animation-delay: 0s movein2;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes movein2 {
  from {
    top: -10px;
    --speed: 0s;
  }
  to {
    top: 110px;
    --speed: 100px;
  }
}
@keyframes movein2 {
  from {
    top: -10px;
    --speed: 0s;
  }
  to {
    top: 110px;
    --speed: 100px;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.movie {
  position: absolute;
  left: 0vw;
  top: 15vh;
  width: 100vw;
  height: 100vh;
  background-image: url("https://mir-s3-cdn-cf.behance.net/project_modules/fs/817a2b118655203.608d7dd0ea447.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #2e2e2e;
  z-index: -2;
  opacity: 1;
  border-radius: 0px 3px 3px 0px;
  border: none;
  -webkit-filter: drop-shadow(5);
          filter: drop-shadow(5);
}
.movie-overlay {
  position: absolute;
  left: 0vw;
  top: 15vh;
  width: 100vw;
  height: 100vh;
  background-size: 1500px;
  background-color: #000;
  z-index: -2;
  opacity: 0.5;
  border-radius: 0px 3px 3px 0px;
  border: none;
  -webkit-filter: drop-shadow(5);
          filter: drop-shadow(5);
}
.movie-overlay-left {
  position: absolute;
  left: 0vw;
  top: 0;
  width: 50vw;
  height: 100vh;
  background-size: 1500px;
  background-color: rgb(161, 161, 161);
  z-index: -2;
  opacity: 0.2;
  border-radius: 0px 3px 3px 0px;
  border: none;
  -webkit-filter: drop-shadow(5);
          filter: drop-shadow(5);
}
.about-section1 {
  width: 35vw;
  top: 70vh;
  height: 45vh;
  bottom: 0;
  right: 0;
  background-color: rgba(60, 83, 119, 0.123);
  border-top-left-radius: 15px;
  position: absolute;
}
.about-header {
  display: block;
  color: rgb(255, 255, 255);
  width: 55vw;
  height: 100vh;
  position: absolute;
  opacity: 0.9;
  margin-left: 2vw;
  font-family: good-times, sans-serif;
  font-weight: 700;
  font-size: 1.2vw;
  margin-top: 3.5vh;
  overflow-y: hidden;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.about-header2 {
  display: block;
  color: rgb(255, 255, 255);
  width: 55%;
  height: 100%;
  position: absolute;
  opacity: 0.9;
  margin-left: 18vw;
  font-family: good-times, sans-serif;
  font-weight: 700;
  font-size: 1.2vw;
  z-index: 1;
  margin-top: 3.5vh;
  overflow-y: hidden;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.about-text {
  display: block;
  color: rgb(255, 255, 255);
  width: 30%;
  height: 100%;
  margin-top: 8vh;
  text-align: left;
  position: relative;
  line-height: 2vh;
  opacity: 0.5;
  margin-left: 2vw;
  font-family: "DM Sans", sans-serif;
  font-weight: 300;
  font-size: 1vw;
  z-index: 1;
  overflow-y: hidden;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.about-text2 {
  display: block;
  color: rgb(255, 255, 255);
  width: 30%;
  height: 100%;
  margin-top: -45vh;
  text-align: left;
  position: relative;
  line-height: 2vh;
  opacity: 0.5;
  margin-left: 18vw;
  font-family: "DM Sans", sans-serif;
  font-weight: 300;
  font-size: 1vw;
  z-index: 1;
  overflow-y: hidden;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.bg-shade {
  background-color: rgba(39, 39, 39, 0.527);
  background-repeat: no-repeat;
  position: fixed;
  opacity: 1;
  background-size: 1500px;
  left: 0%;
  height: 100%;
  width: 100%;
  z-index: -1;
  -webkit-animation: 1.5s ease 0s normal forwards 1 fadein;
          animation: 1.5s ease 0s normal forwards 1 fadein;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.bg {
  background-image: url("https://i.pinimg.com/originals/76/2b/e1/762be1f0ac354249823e8a3ec80a830d.png");
  background-repeat: no-repeat;
  position: fixed;
  background-size: 1500px;
  left: 0%;
  height: 100%;
  width: 100%;
  z-index: -2;
  -webkit-animation: 1.5s ease 0s normal forwards 1 fadein;
          animation: 1.5s ease 0s normal forwards 1 fadein;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.wrapper {
  position: absolute;
  left: 0;
  top: 85%;
  height: 50%;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: -3;
  opacity: 0.8;
  /* background: linear-gradient(180deg, #fff 0%, #77a1bf 50%, #fff 100%); */
}
.wrapper-box {
  display: block;
  position: absolute;
  left: 0;
  top: 200%;
  height: 110%;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 0;
  background: #fff;
  overflow: visible;
}
.about {
  color: #fff;
  margin-top: 50vh;
  width: 40%;
  height: 20%;
  position: relative;
  font-family: "Bebas Neue", cursive;
  font-size: 280%;
  margin-left: 12%;
  font-weight: 500;
  z-index: 1;
  transition: ease-in-out 350ms;
}
.about-grid {
  display: grid;
  position: relative;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100px;
  padding: 5px;
  width: 40%;
  font-weight: lighter;
  align-items: center;
  font-family: "Bebas Neue", cursive;
  font-size: 140%;
  height: 5%;
  text-align: center;
  margin-left: 12%;
}
.icon-1 {
  background-image: url();
  background-repeat: no-repeat;
  position: relative;
  background-size: 1500px;
  left: 0%;
  height: 100%;
  width: 100%;
}
.icon-2 {
  background-image: url();
  background-repeat: no-repeat;
  position: relative;
  background-size: 1500px;
  left: 0%;
  height: 100%;
  width: 100%;
}
.icon-3 {
  background-image: url();
  background-repeat: no-repeat;
  position: relative;
  background-size: 1500px;
  left: 0%;
  height: 100%;
  width: 100%;
}
.icon-4 {
  background-image: url();
  background-repeat: no-repeat;
  position: relative;
  background-size: 1500px;
  left: 0%;
  height: 100%;
  width: 100%;
}
.about-2 {
  display: inline-block;
  grid-column: 2 / 4;
  grid-row: 1;
  width: 50%;
  padding: 0.5rem;
  align-items: center;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 1);
  border-width: 2px;
  border-spacing: 15px;
  border-style: groove;
  align-items: center;
}
.about-3 {
  display: inline-block;
  grid-column: 3 / 4;
  grid-row: 1;
  width: 80%;
  padding: 0.5rem;
  align-items: center;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 1);
  border-width: 2px;
  border-spacing: 15px;
  border-style: groove;
  align-items: center;
}
.about-4 {
  display: inline-block;
  grid-column: 4 / 4;
  grid-row: 1;
  width: 80%;
  padding: 0.5rem;
  align-items: center;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 1);
  border-width: 2px;
  border-spacing: 15px;
  border-style: groove;
  align-items: center;
}
.about-description {
  display: inline-block;
  width: 50vw;
  max-width: 600px;
  padding: 0.5rem;
  grid-column: 1 / 4;
  grid-row: 1;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 1);
  border-width: 2px;
  border-spacing: 15px;
  border-style: groove;
  align-items: center;
}
.rev-block {
  color: rgb(255, 255, 255);
  top: 30vh;
  width: 15%;
  height: 10%;
  position: relative;
  padding: 2rem;
  padding-bottom: 5vh;
  margin-left: 9.2vw;
  margin-top: 0vh;
  font-family: "Bebas Neue", cursive;
  font-size: 2vw;
  z-index: 1;
  overflow-y: hidden;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.kikaru {
  display: block;
  color: rgb(255, 255, 255);
  top: 25.5vh;
  width: 40vw;
  height: 7vh;
  position: relative;
  padding: 2rem;
  padding-bottom: 5vh;
  line-height: 5vh;
  margin-left: 9.25%;
  font-family: good-times, sans-serif;
  font-weight: 700;
  font-size: 7.5rem;
  z-index: 1;
  overflow-y: hidden;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.kikaru-blue {
  position: absolute;
  width: 5%;
  height: 20%;
  background-color: rgb(19, 15, 248);
  margin-left: 10vw;
  margin-top: 30vh;
}
.kikaru-white {
  position: absolute;
  width: 0.2vw;
  height: 5%;
  border-radius: 18px;
  background-color: rgb(255, 255, 255);
  margin-left: 10vw;
  margin-top: 58vh;
}
@-webkit-keyframes rev-block {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}
@keyframes rev-block {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}
@-webkit-keyframes appear-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes appear-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animation_1 {
  background-image: url("https://images-ext-2.discordapp.net/external/Dgikk2GTd1rqqyuOaQFV8rPQWybh-W77RG0nR6utToM/%3Fw%3D1200/https/designandexclusionconf.files.wordpress.com/2017/04/glitch-animation-c-alt-2000x1200.gif?width=1169&height=701");
  background-repeat: no-repeat;
  background-blend-mode: screen;
  background-size: 65%;
  width: 100%;
  height: 10%;
  margin-left: 14%;
  scale: 0.5;
  top: 50%;
  z-index: -5;
  position: absolute;
  -webkit-filter: invert();
          filter: invert();
}
.slide-in {
  color: #fff;
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: fixed;
  opacity: 1;
  top: 0;
  left: 100%;
  z-index: 200;
  -webkit-animation-duration: 0.75s;
          animation-duration: 0.75s;
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
  text-align: center;
  align-items: center;
  -webkit-animation: 0.75s ease 4s normal forwards 1 slide-animation;
          animation: 0.75s ease 4s normal forwards 1 slide-animation;
  transition: ease 350ms;
}
@-webkit-keyframes slide-animation {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
    opacity: 1;
  }
}
@keyframes slide-animation {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
    opacity: 1;
  }
}
@-webkit-keyframes layer {
  0% {
    z-index: 200;
  }
  100% {
    z-index: -10;
  }
}
@keyframes layer {
  0% {
    z-index: 200;
  }
  100% {
    z-index: -10;
  }
}
.cover-in {
  color: #fff;
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  align-items: center;
  position: fixed;
  opacity: 1;
  top: 0;
  text-align: center;
  left: 0;
  z-index: 200;
  -webkit-animation: 0.2s ease 4.1s normal forwards 1 outin,
    0.75s ease 4.4s normal forwards 1 layer;
          animation: 0.2s ease 4.1s normal forwards 1 outin,
    0.75s ease 4.4s normal forwards 1 layer;
  transition: 0s;
}
@-webkit-keyframes outin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes outin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.text-in {
  top: 45%;
  position: fixed;
  align-items: center;
  text-align: center;
  overflow-y: hidden;
  left: 45%;
  -webkit-animation: 0.2s ease 1s normal forwards 1 opspeed,
    1s ease 1s normal forwards 1 transitioning;
          animation: 0.2s ease 1s normal forwards 1 opspeed,
    1s ease 1s normal forwards 1 transitioning;
  letter-spacing: 0.15em;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: block;
  float: left;
  margin-left: 3.125vw;
  font-size: 30px;
  pointer-events: none;
  transition: all 0.1s;
}
@-webkit-keyframes transitioning {
  from {
    left: 45%;
    --speed: 0s;
  }
  to {
    left: 10%;
    --speed: 350ms;
  }
}
@keyframes transitioning {
  from {
    left: 45%;
    --speed: 0s;
  }
  to {
    left: 10%;
    --speed: 350ms;
  }
}
.roadmap {
  font-size: 200%;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: bold;
  font-style: normal;
  font-style: normal;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: #242424;
  text-align: center;
  position: absolute;
  margin-top: 2rem;
  margin-left: 48.5%;
  z-index: -6;
}
.top-angled {
  position: absolute;
  top: -140px;
  left: -50%;
  width: 200%;
  height: 200px;
  -webkit-transform: rotate(4deg);
          transform: rotate(4deg);
  z-index: 0;
  background-color: #242424;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.wrapper-angled {
  position: absolute;
  top: 210%;
  left: -50%;
  width: 200%;
  opacity: 1;
  height: 300px;
  -webkit-transform: rotate(4deg) rotateX(180deg);
          transform: rotate(4deg) rotateX(180deg);
  z-index: 0;
  background: #000;
}
.featured {
  position: relative;
  text-align: center;
  align-items: center;
  font-family: "Bebas Neue", cursive;
  font-size: 40px;
  top: 15%;
  padding: 0.5rem;
  overflow-y: hidden;
  opacity: 1;
  color: #fff;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.featured-wrapper {
  position: relative;
  padding: 5.2rem;
  height: 58%;
  overflow-y: hidden;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.isVisible {
  position: relative;
  text-align: center;
  align-items: center;
  font-family: "Bebas Neue", cursive;
  font-size: 40px;
  top: 15%;
  padding: 0.5rem;
  opacity: 0;
  color: #fff;
  -webkit-animation: 1.5s ease 0s normal forwards 1 fadein;
          animation: 1.5s ease 0s normal forwards 1 fadein;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);
}
.top-LangSelector {
  margin-top: -70px;
  width: 100%;
  position: absolute;
}

.footerspace {
  position: absolute;
  height: 20%;
  width: 100%;
  left: 0;
  bottom: -58%;
  z-index: 1;
}

.btn-show {
  margin-top: 180vh;
  margin-left: 85%;
  position: absolute;
  -webkit-filter: invert(1);
          filter: invert(1);
  text-decoration: none;
  z-index: 5;
}
.btn-show_text {
  color: black;
  text-decoration: none;
}
.btn-down {
  margin-top: 90vh;
  position: absolute;
  margin-left: 5vw;
  height: 100%;
  top: 0;
  -webkit-animation: 5s ease-in-out 0s normal forwards infinite loop1;
          animation: 5s ease-in-out 0s normal forwards infinite loop1;
  -webkit-animation-delay: 0s loop1;
          animation-delay: 0s loop1;
}
.btn-down:hover {
  margin-top: 80vh;
  transition: 800ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.text-4 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  position: relative;
  margin-top: 32vh;
  height: 10vh;
  text-align: center;
  font-size: 35px;
}
.text-5 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  position: relative;
  height: 0%;
  margin-left: 14vw;
  text-align: center;
  font-size: 25px;
  width: 70vw;
}
.homebtn {
  border-radius: 2px;
  margin: 18px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  position: relative;
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: var(--primary--color);
  font-family: "Bebas Neue";
  font-size: 1.35vw;
  letter-spacing: 1.1px;
  text-decoration: none;
}
@-webkit-keyframes loop1 {
  0% {
    margin-top: 90vh;
  }
  40% {
    margin-top: 82vh;
  }
  100% {
    margin-top: 90vh;
  }
}
@keyframes loop1 {
  0% {
    margin-top: 90vh;
  }
  40% {
    margin-top: 82vh;
  }
  100% {
    margin-top: 90vh;
  }
}
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .movie {
    margin-left: 0vw;
    width: 117vw;
    overflow-x: hidden;
  }
  .kikaru {
    width: 100%;
    margin-left: 2vw;
    font-size: 400%;
    margin-top: -5vh;
  }
  .rev-block {
    font-size: 200%;
    width: 100%;
    margin-left: 2vw;
  }
  .description {
    width: 80%;
  }
  .kikaru-blue {
    width: 18%;
    height: 15%;
    margin-left: 4vw;
  }
  .kikaru-white {
    margin-top: 50.5vh;
  }
  #homeButtonContainer {
    margin-left: 9vw;
    margin-top: 16vh;
    z-index: 3;
  }
  .movie-overlay {
    position: absolute;
    left: 0vw;
    top: 15vh;
    width: 117vw;
    height: 100vh;
    background-size: 1500px;
    background-color: #000;
    z-index: -2;
    opacity: 0.5;
    border-radius: 0px 3px 3px 0px;
    border: none;
    -webkit-filter: drop-shadow(5);
            filter: drop-shadow(5);
  }
  .about-section1 {
    width: 100vw;
    top: 70vh;
    height: 45vh;
    bottom: 0;
    right: -3.5vh;
    background-color: rgba(60, 83, 119, 0.123);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: absolute;
  }
  .about-header {
    font-size: 120%;
    margin-left: 10vw;
    margin-top: 5vh;
  }
  .about-header2 {
    font-size: 120%;
    margin-top: 5vh;
    width: 48%;
    margin-left: 60vw;
  }
  .about-text {
    font-size: 115%;
    text-align: center;
    margin-top: 10vh;
    margin-left: 11.5vw;
  }
  .about-text2 {
    font-size: 115%;
    text-align: center;
    margin-left: 60vw;
  }
  .movie-overlay-left {
    width: 50%;
  }
  .btn-down {
    margin-left: 42.5vw;
    z-index: 2;
  }
  .btn-show {
    margin-top: 190vh;
  }
  .text-4 {
    margin-top: -20vh;
    margin-left: 15vw;
  }
  .text-5 {
    margin-left: 8vw;
    width: 100%;
  }
  .homebtn {
    padding: 1.5vh 1.25vw;
    border-radius: 1px;
    margin: 20px;
    border: none;
    position: absolute;
    outline: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: all ease-in-out 250ms;
    background-color: #000;
    font-family: "Bebas Neue";
    font-size: 1.35vw;
    letter-spacing: 1.1px;
    margin-right: -1vw;
    -webkit-transform: scale(3);
            transform: scale(3);
    margin-top: 1vh;
    text-decoration: none;
    top: 0;
    right: 0;
  }
  #loggedInButton {
    position: absolute;
    z-index: 5;
    margin-top: 5.75vh;
    height: auto;
    padding: 0.65vh 2.45vw;
    text-align: center;
    right: 0;
    font-size: 1.8vw;
  }
  .top-LangSelector {
    opacity: 0;
  }
}
.featured-line {
  width: 100vw;
  height: 45%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  margin-top: 145vh;
  background-color: #181818;
}
.featured-text {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 136vh;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 35px;
  text-align: center;
  align-items: center;
  width: 100vw;
  height: 10vh;
}

/* Apply these classes to any input box you would like to have consistent styling with */

:root {
  --primary--color: rgb(0, 0, 0);
  --button--hover--background--color: rgb(100, 100, 100);
  --main--blue: rgb(0, 187, 255);
  --default--container--button--color: rgb(48, 48, 48);
}

a {
  padding: 18px;
}

/* for buttons */
.defaultButton {
  padding: 1.25vh 1.25vw;
  border-radius: 2px;
  margin: 18px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: rgb(0, 0, 0);
  background-color: var(--primary--color);
  font-family: "Bebas Neue";
  font-size: 1.35vw;
  letter-spacing: 1.1px;
  text-decoration: none;
}

.defaultButtonHollowed {
  background-color: transparent;
  border-style: solid;
  border-color: #fff;
  border-width: 2px;
  color: #fff;
}

.defaultButtonHollowed:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.defaultButtonOnlyText {
  background-color: transparent !important;
}

.defaultButtonOnlyText:hover {
  color: rgb(0, 187, 255) !important;
  color: var(--main--blue) !important;
}

.defaultButton:hover {
  background-color: rgb(100, 100, 100);
  background-color: var(--button--hover--background--color);
  color: rgb(0, 187, 255);
  color: var(--main--blue);
  /* letter-spacing: 1.8px; */
}

/* from old button classes, with a few changes */
.btn {
  outline: none;
  border: none;
  z-index: 3;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.btn--primary {
  background-color: rgb(0, 0, 0);
  background-color: var(--primary--color);
  color: #fff;
  border: 2px solid rgb(0, 0, 0);
  border: 2px solid var(--primary--color);
}

.btn--primary:hover {
  border-color: rgb(100, 100, 100);
  border-color: var(--button--hover--background--color);
}

/* for inputs */
.defaultInput {
  padding: 12.5px;
  margin: 10px;
  border-radius: 3px;
  outline: none;
  cursor: text;
  font-family: "Bebas Neue";
  letter-spacing: 1.2px;
  border-style: none;
  background-color: rgb(73, 73, 73);
  transition-duration: 100ms;
  color: rgb(0, 187, 255);
}

.defaultInput:hover {
  color: rgb(0, 187, 255);
}

/* .defaultInput input, select, textarea {
    color: rgb(116, 114, 112) !important;
} */

.defaultInput::-webkit-input-placeholder {
  color: rgb(116, 114, 112);
}

.defaultInput:-ms-input-placeholder {
  color: rgb(116, 114, 112);
}

.defaultInput::placeholder {
  color: rgb(116, 114, 112);
}

.defaultInput:hover {
  background-color: rgb(100, 100, 100);
  background-color: var(--button--hover--background--color);
}

/* for divs */
.defaultContainer {
  font-family: "Bebas Neue";
  border-radius: 2px;
  letter-spacing: 1.2px;
  border-style: none;
  padding: 40px;
  background-color: rgb(0, 0, 0);
  position: absolute;
}

.defaultContainer button {
  background-color: rgb(48, 48, 48);
  background-color: var(--default--container--button--color);
}

.defaultContainer h1 {
  color: rgb(134, 134, 134) !important;
  margin: 10px;
  font-size: 30px;
  display: inline-block;
}

.defaultContainer h1:hover {
  color: rgb(255, 255, 255) !important;
  margin: 10px;
  font-size: 30px;
  display: inline-block;
}
.defaultButton2 {
  padding: 15px 25px;
  border-radius: 4px;
  margin: 18px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: rgb(0, 0, 0);
  background-color: var(--primary--color);
  font-family: "Bebas Neue";
  font-size: 6vw;
  letter-spacing: 1.1px;
  text-decoration: none;
}
.defaultButton2:hover {
  background-color: rgb(100, 100, 100);
  background-color: var(--button--hover--background--color);
  color: rgb(0, 187, 255);
  color: var(--main--blue);
  /* letter-spacing: 1.8px; */
}


@media only screen and (min-width: 300px) and (max-width: 600px) {
  .defaultButton {
    padding: 15px 25px;
    border-radius: 4px;
    margin: 18px;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: all ease-in-out 250ms;
    background-color: rgb(0, 0, 0);
    background-color: var(--primary--color);
    font-family: "Bebas Neue";
    font-size: 6vw;
    letter-spacing: 1.1px;
    text-decoration: none;
  }
  .defaultButton2 {
    padding: 1.25vh 1.25vw;
    border-radius: 2px;
    margin: 18px;
    border: none;
    right: 0;
    outline: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: all ease-in-out 250ms;
    background-color: rgb(0, 0, 0);
    background-color: var(--primary--color);
    font-family: "Bebas Neue";
    font-size: 1.35vw;
    letter-spacing: 1.1px;
    margin-right: -10vw;
    text-decoration: none;
  }
}

.greyedOutField {
  background-color: grey;
  color: rgb(83, 83, 83);
  border-color: rgba(red, green, blue, 0);
}
.greyedOutField:hover {
  cursor: default;
  background-color: grey;
  color: rgb(83, 83, 83);
  border-color: rgba(0, 0, 0, 0);
}

/* ONLY FOR CSS ELEMENTS IN LOGIN.JS, ONLY IMPORT THIS INTO THAT FILE TO AVOID PROBLEMS*/

.loginContainer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.loginPopup {
}

.loginLabel {
}

.signUpLabel {
}

.password {
}

.button {
}

.email {
  margin-top: 0px;
}

.label {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 38px !important;
  cursor: pointer;
  transition-duration: 250ms;
}

.labelHighlighted {
  border-color: var(--main--blue);
}

.coloredBar {
  color: var(--main--blue);
  width: 100%;
  height: 1%;
}

.signUpLabel {
  /* position: absolute; */
  /* right: 10px !important; */
}

.Modal {
  display: flex;
  top: 22vh;
  left: 50vw;
  width: 20%;
  height: 50%;
  flex-direction: column;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(29, 29, 29, 0.849);
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 5;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.loginBtn {
  margin-top: 10px;
}

.openLoginModal {
  position: absolute;
  font-size: 150%;
  padding: 8px 20px;
  z-index: 3;
  right: 10px !important;
}

.openLoginModal:hover {
  cursor: pointer;
}

.forgotPasswordBtn {
  padding: 10px;
  padding-bottom: none;
  text-align: left;
  border-radius: 4px;
  border: none;
  outline: none;
  color: rgb(112, 112, 112);
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: var(--primary--color);
  font-family: "Bebas Neue";
  font-size: 2.1vh;
  letter-spacing: 1.1px;
}

.loginInput {
  text-align: center;
  font-size: 2.8vh;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-style: normal;
}

.signUpTabBtn {
}

.modalLabelContainer {
  display: flex;
  justify-content: space-evenly;
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .Modal {
    display: flex;
    top: 22vh;
    left: 1vw;
    width: 80%;
    height: 50%;
    flex-direction: column;
    z-index: 500000;
  }
  .WhiteContainer {
    opacity: 0;
  }
}

.badField {
  border-color: rgb(255, 133, 133);
  border-style: solid;
}

.goodField {
  border-color: rgb(133, 255, 153);
  border-style: solid;
}

.WhiteContainer {
  background-color: rgb(255, 255, 255);
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: -25vw;
  background-image: url("https://mir-s3-cdn-cf.behance.net/project_modules/fs/817a2b118655203.608d7dd0ea447.png");
  background-position: 0px -160px;
  background-size: 1500px;
}

.svg_logo3 {
  -webkit-transform: scale(0.15);
          transform: scale(0.15);
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100000;
  opacity: 0.8;
  margin-left: -35%;
  margin-top: -45%;
  -webkit-filter: invert(0);
          filter: invert(0);
}

.cls-3 {
  fill: #fff;
  stroke: #fff;
  stroke-miterlimit: 10;
}

.full,
.full-2,
.full-3 {
  fill: #231f20;
}
.full {
  stroke: #231f20;
  stroke-miterlimit: 10;
}
.full-2 {
  font-size: 99.62px;
}
.full-2,
.full-3 {
  font-family: GoodTimesRg-Bold, Good Times;
  font-weight: 700;
}
.full-3 {
  font-size: 96.23px;
}
.svg-logo-full2 {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  position: absolute;
  width: 50%;
  height: 50%;
  left: 4%;
  z-index: 100000;
  margin-top: 0%;
  opacity: 1;
  transition: all 0.75s ease-in-out;
  z-index: 100;
}

.welcome {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  font-size: 200%;
  margin-left: -9vw;
  margin-top: 35%;
  position: relative;
  color: white;
  opacity: 0.9;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.welcome2 {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  margin-left: -11.5vw;
  width: 50%;
  margin-top: 80%;
  position: relative;
  color: white;
  opacity: 0.9;
  text-align: center;
}

.UserCard {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 60vh;
}
#Card {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -5%;
  opacity: 1;
  overflow: hidden;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  /*animation-iteration-count: infinite;
  animation-name: fade, slide2;
  animation-duration: 5s;
  animation-delay: 1s;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56); */
  -webkit-filter: drop-shadow(0px 20px 20px rgba(148, 158, 247, 0.699));
          filter: drop-shadow(0px 20px 20px rgba(148, 158, 247, 0.699));
}
#Card2 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 20%;
  top: -5%;
  opacity: 1;
  overflow: hidden;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  /*animation-iteration-count: infinite;
  animation-name: fade, slide2;
  animation-duration: 5s;
  animation-delay: 1s;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56); */
  -webkit-filter: drop-shadow(0px 20px 20px rgba(148, 158, 247, 0.699));
          filter: drop-shadow(0px 20px 20px rgba(148, 158, 247, 0.699));
}
#Card3 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 40%;
  top: -5%;
  overflow: hidden;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  /*animation-iteration-count: infinite;
  animation-name: fade, slide3;
  animation-duration: 5s;
  animation-delay: 1.5s;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);*/
  opacity: 1;
  -webkit-filter: drop-shadow(0px 20px 20px rgba(148, 158, 247, 0.699));
          filter: drop-shadow(0px 20px 20px rgba(148, 158, 247, 0.699));
}
#Card-h {
  position: absolute;
  width: 416px;
  height: 518px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#Rectangle_1 {
  fill: rgba(255, 255, 255, 1);
}
.Rectangle_1 {
  position: absolute;
  overflow: visible;
  width: 416px;
  height: 548px;
  left: 0px;
  top: 0px;
}
#banner {
  position: absolute;
  width: 416px;
  height: 260px;
  left: 0px;
  border-radius: 20px 20px 0px 0px;
  top: 0px;
  object-fit: cover;
  overflow: hidden;
}
#Path_1 {
  fill: rgba(255, 255, 255, 1);
}
.Path_1 {
  overflow: visible;
  position: absolute;
  width: 100%;
  height: 449.688px;
  left: 0.5px;
  top: 34.134px;
  -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
          transform: matrix(1, 0, 0, 1, 0, 0);
}
#Rectangle_2 {
  fill: rgb(255, 255, 255);
}
.Rectangle_2 {
  position: absolute;
  overflow: visible;
  width: 416px;
  height: 67px;
  left: 0px;
  top: 481px;
}
#Rectangle_4 {
  fill: rgb(255, 255, 255);
}
.Rectangle_4 {
  position: absolute;
  overflow: visible;
  width: 416px;
  height: 67px;
  left: 0px;
  top: 481px;
}
#Rectangle_5 {
  fill: rgb(255, 255, 255);
}
.Rectangle_5 {
  position: absolute;
  overflow: visible;
  width: 416px;
  height: 67px;
  left: 0px;
  top: 481px;
}
#Line_1 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_1 {
  overflow: visible;
  position: absolute;
  width: 416.477px;
  height: 226.879px;
  left: 0.5px;
  top: 33.5px;
  -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
          transform: matrix(1, 0, 0, 1, 0, 0);
}
#pfp {
  position: absolute;
  width: 222px;
  height: 222px;
  left: 23.3%;
  border-radius: 150px;
  top: 7.2%;
  overflow: visible;
}
#username {
  top: 253px;
  position: relative;
  text-align: center;
  align-items: center;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-weight: normal;
  padding: 0.5rem;

  font-size: 40px;
  color: rgba(0, 0, 0, 1);
}
#bio {
  left: 40px;
  top: 318px;
  position: absolute;
  overflow: visible;
  width: 337px;
  text-align: center;
  align-items: center;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: rgba(0, 0, 0, 1);
}
#___________ {
  left: 11px;
  top: 318px;
  position: absolute;
  overflow: visible;
  width: 395px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: rgba(0, 0, 0, 1);
}
#_ {
  left: 144px;
  top: 457px;
  position: absolute;
  overflow: visible;
  width: 129px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: rgba(0, 0, 0, 1);
}
br {
  text-align: center;
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes slide {
  0% {
    left: 40%;
  }
  50% {
    left: 0%;
  }
  80% {
    left: -10%;
  }
  100% {
    left: 40%;
  }
}
@keyframes slide {
  0% {
    left: 40%;
  }
  50% {
    left: 0%;
  }
  80% {
    left: -10%;
  }
  100% {
    left: 40%;
  }
}
@-webkit-keyframes slide2 {
  0% {
    left: 40%;
  }
  50% {
    left: 20%;
  }
  80% {
    left: -10%;
  }
  100% {
    left: 40%;
  }
}
@keyframes slide2 {
  0% {
    left: 40%;
  }
  50% {
    left: 20%;
  }
  80% {
    left: -10%;
  }
  100% {
    left: 40%;
  }
}
@-webkit-keyframes slide3 {
  0% {
    left: 60%;
  }
  50% {
    left: 40%;
  }
  80% {
    left: -10%;
  }
  100% {
    left: 40%;
  }
}
@keyframes slide3 {
  0% {
    left: 60%;
  }
  50% {
    left: 40%;
  }
  80% {
    left: -10%;
  }
  100% {
    left: 40%;
  }
}
#Artist {
  left: 105px;
  top: 460px;
  position: absolute;
}
.viewBtn {
  border-radius: 2px;
  border: none;
  outline: none;
  padding: 14px 30px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  position: relative;
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: var(--primary--color);
  font-family: "Bebas Neue";
  font-size: 2.1vw;
  letter-spacing: 1.1px;
  text-decoration: none;
  text-align: center;
  width: 10vw;
  height: 10vh;
  z-index: 10;
}

.text-3 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  position: relative;
  margin-top: -30%;
  height: 10%;
  margin-right: 15%;
  text-align: right;
  font-size: 25px;
}
.card-1 {
  width: 50vh;
  height: 70vh;
  background-color: rgb(43, 43, 43);
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 15vh;
  margin-left: 10vw;
  border-radius: 15px;
  -webkit-filter: drop-shadow(-10px 25px 8px #0b0bbe);
          filter: drop-shadow(-10px 25px 8px #0b0bbe);
  align-items: center;
}
.icon-1 {
  background-image: url("https://cdn.discordapp.com/attachments/870491737834000415/933230829243596860/icon-1.gif");
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
  background-position: left;
  width: 50vw;
  -webkit-transform: scale(1);
          transform: scale(1);
  height: 50vh;
  top: 0;
  left: 0;
  margin-top: -5vh;
  margin-left: 1.5vw;
}
.text-1 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  position: absolute;
  margin-top: 42vh;
  margin-left: 2.5vw;
  color: white;
  height: 10%;
  width: 20vw;
  text-align: center;
  font-size: 25px;
}
.card-2 {
  width: 50vh;
  height: 70vh;
  background-color: rgb(43, 43, 43);
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 15vh;
  margin-left: 37.5vw;
  border-radius: 15px;
  align-items: center;
  -webkit-filter: drop-shadow(-10px 25px 8px #0b0bbe);
          filter: drop-shadow(-10px 25px 8px #0b0bbe);
}
.card-3 {
  width: 50vh;
  height: 70vh;
  background-color: rgb(43, 43, 43);
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 15vh;
  margin-left: 65vw;
  border-radius: 15px;
  -webkit-filter: drop-shadow(-10px 25px 8px #0b0bbe);
          filter: drop-shadow(-10px 25px 8px #0b0bbe);
  align-items: center;
}
.text-2 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  position: absolute;
  margin-top: 45vh;
  margin-left: 0.4vw;
  color: white;
  height: 10%;
  width: 24vw;
  text-align: center;
  font-size: 25px;
}
.about-cards {
  margin-top: -5vh;
}


.profileHeaderBox {
  left: 5vw;
  width: 90.1vw;
  height: 32.5vh;
  position: relative;
  background-color: grey;
  border-radius: 8px;
  margin-top: 14vh;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-size: cover;
  background-position: 0px 55%;
  background-repeat: no-repeat;
  /* filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.514)); */
}
.profileMainBackground {
  max-width: 100vw;
  max-height: 100vh;
  object-fit: contain;
}

.followBtn {
  position: absolute;
  right: 1vw !important;
  bottom: 1vh;
}

.pfp {
  position: absolute;
  border-radius: 100px;
  background-color: white;
  background-size: 180px;
  background-repeat: no-repeat;
  top: 0;
  z-index: 2;
  margin-top: 40vh;
  margin-left: 20vw;
  height: 180px;
  width: 180px;
  align-items: center;
}
p {
  text-align: left;
  font-family: "DM Sans", sans-serif;
  margin-top: 4.5vh;
  position: absolute;
  color: rgb(53, 53, 53);
  margin-left: 33vw;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
}
.name {
  text-align: left;
  font-family: "DM Sans", sans-serif;
  margin-top: 1vh;
  position: absolute;
  color: #000;
  margin-left: 33vw;
  font-size: 25px;
  font-weight: 500;
  align-items: center;
}
.bio {
  text-align: center;
  font-family: "DM Sans", sans-serif;
  margin-top: 12vh;
  position: absolute;
  color: rgb(17, 17, 17);
  font-size: 15px;
  font-weight: 500;
  align-items: center;
  width: 30vw;
  height: 10vh;
  margin-left: 35vw;
}

.sectionContainer {
  width: 90vw;
  display: none;
  align-items: center;
  height: 100vh;
  top: 0;
  margin-left: 5vw;
  position: absolute;
  background-color: #fff;
  border-color: #000;
  border: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-bottom-style: solid;
  margin-top: 41vh;
  z-index: -1;
}

.followBtn {
  border-color: #000;
  border-radius: 25px;
  border: 1px;
  border-style: solid;
  right: 0;
  margin-right: 20vw;
  margin-top: 51.5vh;
  top: 0;
  background-color: #fff;
  position: absolute;
  width: 10vw;
  height: 5vh;
  z-index: 50;
  cursor: pointer;
}
.followBtn:hover {
  background-color: rgb(209, 209, 209);
}
.followBtn:active {
  background-color: rgb(143, 143, 143);
}
/* this will be later once we have a post/feed thing for other posts */
.feedPageBtn {
}
.followBtn .text {
  text-align: center;
  margin-top: 6%;
  font-family: "DM Sans", sans-serif;
}
/* portfolio area /// image import page */
.mediaPageBtn {
  position: absolute;
  align-items: center;
  display: none;
  background-color: #fff;
  width: 6vw;
  top: 0;
  left: 0;
  margin-top: 30.5vh;
  margin-left: 8vw;
  height: 4vh;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 1.15rem;
  cursor: pointer;
}
/* services page for like commissions */
.servicesPageBtn {
  display: none;
  position: relative;
  align-items: center;
  background-color: #fff;
  width: 6vw;
  top: 0;
  left: 0;
  margin-top: 30.5vh;
  margin-left: 16vw;
  height: 4vh;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 1.15rem;
  /*border-right-style: solid;
  border-left-style: solid;
  border-top-style: solid;
  border-top-style: solid; */
  border-color: #000;
  border-width: 1px;
  cursor: pointer;
}
.servicesPageBtn:hover {
  border-color: rgb(0, 187, 255);
  border-bottom-style: solid;
  border-width: 4px;
}
.mediaPageBtn:hover {
  border-color: rgb(0, 187, 255);
  border-bottom-style: solid;
  border-width: 4px;
}

.contentPage {
  width: 90vw;
  align-items: center;
  height: 45vh;
  top: 0;
  margin-left: 5vw;
  position: absolute;
  background-color: #fff;
  border-color: #fff;
  border: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-bottom-style: solid;
  margin-top: 38vh;
  z-index: -2;
}

.background {
  position: absolute;
  width: 100vw;
  height: 500vh;
  top: 0;
  left: 0;
  z-index: -5;
  background-color: black;
  margin-top: 0vh;
}

.twitter {
  background-image: url("https://toppng.com/public/uploads/thumbnail/follow-me-gold-twitter-icon-vector-11563031490hal0n9kvls.png");
  background-repeat: no-repeat;
  width: 40vh;
  height: 20vw;
  -webkit-transform: scale(0.09);
          transform: scale(0.09);
  top: 0;
  margin-left: 54.5vw;
  margin-top: 32.8vh;
  z-index: 10;
  position: absolute;
  cursor: pointer;
}

