.global-icon {
  background-image: url("https://cdn-icons-png.flaticon.com/512/117/117479.png");
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  top: 0;
  margin-left: -50vw;
  margin-top: -18.2%;
  transform: scale(0.04);
  width: 100vw;
  height: 100vh;
  left: 0;
}
.lang_switch {
  margin-left: 5px;
  left: 5px;
}
.lang-container {
  margin-left: 5vw;
  height: 3vh;
  position: relative;
}
.lang_switch button {
  padding: 2px;
  font-size: 1.3rem;
  font-family: "Bebas Neue", cursive;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  letter-spacing: 0.05vw;
  cursor: pointer;
  color: #242424;
  text-align: center;
}
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .lang-container {
    opacity: 0;
    margin-left: 5vw;
  }
  .footer .lang-container {
    opacity: 1;
    color: white;
    margin-top: -39vh;
    z-index: 10;
    font-size: 40%;
    margin-left: 39vw;
  }
}
