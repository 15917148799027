
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Akira Expanded", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*.loading {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  color: black;
  z-index: 100;
  filter: drop-shadow(500px);
  animation: 1s ease 0s normal forwards 1 fadein2;
  animation-delay: 3s;
}*/

/* * {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
} */


@keyframes fadein2 {
  0% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
