@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap");

.footer {
  background-color: #000;
  position: absolute;
  width: 100%;
  left: 0%;
  max-width: auto;
  height: 70%;
  z-index: 5;
  bottom: -250%;
  padding: 0;
  border-radius: 0;
  color: #fff;
  overflow: hidden;
}

.lang_switch {
  position: absolute;
  font-family: "Bebas Neue", cursive;
  font-size: 120%;
  margin-bottom: 10.5rem;
  margin-top: 100px;
  margin-left: 1.7rem;
  z-index: 3;
}

.footer .lang_switch {
  position: absolute;
  font-family: "Bebas Neue", cursive;
  font-size: 120%;
  margin-bottom: 10.5rem;
  margin-top: 100px;
  margin-left: 1.7rem;
  z-index: 3;
  color: #fff;
}

p {
  text-align: right;
  margin-right: 1rem;
  padding: 0rem;
  color: white;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
}

body:before {
  content: "";
  height: 100%;
  float: left;
  width: 0;
  margin-top: -32767px;
  /* thank you Erik J - negate effect of float*/
}

.footer .text {
  letter-spacing: 0.15em;
  line-height: 1em;
  text-align: center;
  font-size: 200%;
  margin-top: 6%;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  position: relative;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.footer .text_2 {
  letter-spacing: 0.15em;
  line-height: 1em;
  text-align: center;
  font-size: 100%;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  width: 100%;
  margin-top: 4%;
  position: relative;
  cursor: pointer;
}

.footer .text_3 {
  letter-spacing: 0.15em;
  line-height: 1em;
  text-align: center;
  font-size: 100%;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  height: 0%;
  margin-top: 2%;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

.footer .c_com {
  letter-spacing: 0.15em;
  line-height: 1em;
  text-align: center;
  font-size: 80%;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  margin-top: 5%;
  position: relative;
}

.footer .page_terms {
  letter-spacing: 0.15em;
  line-height: 1em;
  text-align: center;
  font-size: 60%;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  margin-top: 1%;
  position: relative;
}

.footer .p_t {
  letter-spacing: 0.15em;
  line-height: 1em;
  text-align: center;
  font-size: 80%;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  margin-top: 4%;
  position: relative;
}

.footer .svg_logo2 {
  transform: scale(8);
  position: absolute;
  width: 5%;
  height: 5%;
  left: 10%;
  z-index: 100000;
  margin-top: 12%;
  opacity: 1;
  filter: invert(0);
}

.footer .cls-2 {
  fill: #fff;
  stroke: #fff;
  stroke-miterlimit: 10;
}

@media only screen and (max-width: 800px) {
  .footer {
    align-items: bottom;
    bottom: -250%;
    width: 117%;
  }
  .footer .svg_logo2 {
    transform: scale(8);
    position: absolute;
    width: 15%;
    height: 15%;
    left: 6%;
    z-index: 100000;
    margin-top: 12%;
    opacity: 1;
    filter: invert(0);
  }
  .footer .c_com {
    margin-top: 10vh;
  }
  .footer .p_t {
    margin-top: 10vh;
  }
  .footer .page_terms {
    width: 90%;
    margin-left: 5vw;
    margin-top: 1vh;
  }
  .footer .text_2 {
    margin-top: 5vh;
  }
  .footer .text_3 {
    margin-top: 5vh;
  }
  .footer .text {
    margin-top: 5vh;
  }
}
