/* ONLY FOR CSS ELEMENTS IN LOGIN.JS, ONLY IMPORT THIS INTO THAT FILE TO AVOID PROBLEMS*/
@import url("https://use.typekit.net/nws1eon.css");

.loginContainer {
  height: fit-content;
  width: fit-content;
}

.loginPopup {
}

.loginLabel {
}

.signUpLabel {
}

.password {
}

.button {
}

.email {
  margin-top: 0px;
}

.label {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 38px !important;
  cursor: pointer;
  transition-duration: 250ms;
}

.labelHighlighted {
  border-color: var(--main--blue);
}

.coloredBar {
  color: var(--main--blue);
  width: 100%;
  height: 1%;
}

.signUpLabel {
  /* position: absolute; */
  /* right: 10px !important; */
}

.Modal {
  display: flex;
  top: 22vh;
  left: 50vw;
  width: 20%;
  height: 50%;
  flex-direction: column;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(29, 29, 29, 0.849);
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 5;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.loginBtn {
  margin-top: 10px;
}

.openLoginModal {
  position: absolute;
  font-size: 150%;
  padding: 8px 20px;
  z-index: 3;
  right: 10px !important;
}

.openLoginModal:hover {
  cursor: pointer;
}

.forgotPasswordBtn {
  padding: 10px;
  padding-bottom: none;
  text-align: left;
  border-radius: 4px;
  border: none;
  outline: none;
  color: rgb(112, 112, 112);
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: var(--primary--color);
  font-family: "Bebas Neue";
  font-size: 2.1vh;
  letter-spacing: 1.1px;
}

.loginInput {
  text-align: center;
  font-size: 2.8vh;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-style: normal;
}

.signUpTabBtn {
}

.modalLabelContainer {
  display: flex;
  justify-content: space-evenly;
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .Modal {
    display: flex;
    top: 22vh;
    left: 1vw;
    width: 80%;
    height: 50%;
    flex-direction: column;
    z-index: 500000;
  }
  .WhiteContainer {
    opacity: 0;
  }
}

.badField {
  border-color: rgb(255, 133, 133);
  border-style: solid;
}

.goodField {
  border-color: rgb(133, 255, 153);
  border-style: solid;
}

.WhiteContainer {
  background-color: rgb(255, 255, 255);
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: -25vw;
  background-image: url("https://mir-s3-cdn-cf.behance.net/project_modules/fs/817a2b118655203.608d7dd0ea447.png");
  background-position: 0px -160px;
  background-size: 1500px;
}

.svg_logo3 {
  transform: scale(0.15);
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100000;
  opacity: 0.8;
  margin-left: -35%;
  margin-top: -45%;
  filter: invert(0);
}

.cls-3 {
  fill: #fff;
  stroke: #fff;
  stroke-miterlimit: 10;
}

.full,
.full-2,
.full-3 {
  fill: #231f20;
}
.full {
  stroke: #231f20;
  stroke-miterlimit: 10;
}
.full-2 {
  font-size: 99.62px;
}
.full-2,
.full-3 {
  font-family: GoodTimesRg-Bold, Good Times;
  font-weight: 700;
}
.full-3 {
  font-size: 96.23px;
}
.svg-logo-full2 {
  transform: scale(0.6);
  position: absolute;
  width: 50%;
  height: 50%;
  left: 4%;
  z-index: 100000;
  margin-top: 0%;
  opacity: 1;
  transition: all 0.75s ease-in-out;
  z-index: 100;
}

.welcome {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  font-size: 200%;
  margin-left: -9vw;
  margin-top: 35%;
  position: relative;
  color: white;
  opacity: 0.9;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.welcome2 {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  margin-left: -11.5vw;
  width: 50%;
  margin-top: 80%;
  position: relative;
  color: white;
  opacity: 0.9;
  text-align: center;
}
