.UserCard {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 60vh;
}
#Card {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -5%;
  opacity: 1;
  overflow: hidden;
  transform: scale(0.6);
  /*animation-iteration-count: infinite;
  animation-name: fade, slide2;
  animation-duration: 5s;
  animation-delay: 1s;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56); */
  filter: drop-shadow(0px 20px 20px rgba(148, 158, 247, 0.699));
}
#Card2 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 20%;
  top: -5%;
  opacity: 1;
  overflow: hidden;
  transform: scale(0.6);
  /*animation-iteration-count: infinite;
  animation-name: fade, slide2;
  animation-duration: 5s;
  animation-delay: 1s;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56); */
  filter: drop-shadow(0px 20px 20px rgba(148, 158, 247, 0.699));
}
#Card3 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 40%;
  top: -5%;
  overflow: hidden;
  transform: scale(0.6);
  /*animation-iteration-count: infinite;
  animation-name: fade, slide3;
  animation-duration: 5s;
  animation-delay: 1.5s;
  transition: all 0.75s cubic-bezier(0.71, 0, 0.33, 1.56);*/
  opacity: 1;
  filter: drop-shadow(0px 20px 20px rgba(148, 158, 247, 0.699));
}
#Card-h {
  position: absolute;
  width: 416px;
  height: 518px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#Rectangle_1 {
  fill: rgba(255, 255, 255, 1);
}
.Rectangle_1 {
  position: absolute;
  overflow: visible;
  width: 416px;
  height: 548px;
  left: 0px;
  top: 0px;
}
#banner {
  position: absolute;
  width: 416px;
  height: 260px;
  left: 0px;
  border-radius: 20px 20px 0px 0px;
  top: 0px;
  object-fit: cover;
  overflow: hidden;
}
#Path_1 {
  fill: rgba(255, 255, 255, 1);
}
.Path_1 {
  overflow: visible;
  position: absolute;
  width: 100%;
  height: 449.688px;
  left: 0.5px;
  top: 34.134px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Rectangle_2 {
  fill: rgb(255, 255, 255);
}
.Rectangle_2 {
  position: absolute;
  overflow: visible;
  width: 416px;
  height: 67px;
  left: 0px;
  top: 481px;
}
#Rectangle_4 {
  fill: rgb(255, 255, 255);
}
.Rectangle_4 {
  position: absolute;
  overflow: visible;
  width: 416px;
  height: 67px;
  left: 0px;
  top: 481px;
}
#Rectangle_5 {
  fill: rgb(255, 255, 255);
}
.Rectangle_5 {
  position: absolute;
  overflow: visible;
  width: 416px;
  height: 67px;
  left: 0px;
  top: 481px;
}
#Line_1 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_1 {
  overflow: visible;
  position: absolute;
  width: 416.477px;
  height: 226.879px;
  left: 0.5px;
  top: 33.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#pfp {
  position: absolute;
  width: 222px;
  height: 222px;
  left: 23.3%;
  border-radius: 150px;
  top: 7.2%;
  overflow: visible;
}
#username {
  top: 253px;
  position: relative;
  text-align: center;
  align-items: center;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-weight: normal;
  padding: 0.5rem;

  font-size: 40px;
  color: rgba(0, 0, 0, 1);
}
#bio {
  left: 40px;
  top: 318px;
  position: absolute;
  overflow: visible;
  width: 337px;
  text-align: center;
  align-items: center;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: rgba(0, 0, 0, 1);
}
#___________ {
  left: 11px;
  top: 318px;
  position: absolute;
  overflow: visible;
  width: 395px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: rgba(0, 0, 0, 1);
}
#_ {
  left: 144px;
  top: 457px;
  position: absolute;
  overflow: visible;
  width: 129px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: rgba(0, 0, 0, 1);
}
br {
  text-align: center;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide {
  0% {
    left: 40%;
  }
  50% {
    left: 0%;
  }
  80% {
    left: -10%;
  }
  100% {
    left: 40%;
  }
}
@keyframes slide2 {
  0% {
    left: 40%;
  }
  50% {
    left: 20%;
  }
  80% {
    left: -10%;
  }
  100% {
    left: 40%;
  }
}
@keyframes slide3 {
  0% {
    left: 60%;
  }
  50% {
    left: 40%;
  }
  80% {
    left: -10%;
  }
  100% {
    left: 40%;
  }
}
#Artist {
  left: 105px;
  top: 460px;
  position: absolute;
}
.viewBtn {
  border-radius: 2px;
  border: none;
  outline: none;
  padding: 14px 30px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  position: relative;
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: var(--primary--color);
  font-family: "Bebas Neue";
  font-size: 2.1vw;
  letter-spacing: 1.1px;
  text-decoration: none;
  text-align: center;
  width: 10vw;
  height: 10vh;
  z-index: 10;
}
